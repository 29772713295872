import React, { Ref } from 'react'

export interface IContainerProps {
    id?: string
    direction?: 'column' | 'row'
    style?: React.CSSProperties
    className?: string
    alignItems?: 'flex-start' | 'flex-end' | 'center'
    justifyContent?: 'flex-start' | 'flex-end' | 'center'
    flex?: number
    scrollY?: boolean
    flexWrap?:
        | '-moz-initial'
        | 'inherit'
        | 'initial'
        | 'revert'
        | 'unset'
        | 'nowrap'
        | 'wrap'
        | 'wrap-reverse'
        | undefined
    ref?: Ref<HTMLDivElement>
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onDoubleClick?: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => void
    onMouseOver?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onMouseOut?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onDragOver?: React.DragEventHandler<HTMLDivElement>
    onDragEnd?: React.DragEventHandler<HTMLDivElement>
    onDrop?: React.DragEventHandler<HTMLDivElement>
    onDragLeave?: React.DragEventHandler<HTMLDivElement>
}

export const Container: React.FC<IContainerProps> = React.forwardRef<
    HTMLDivElement,
    IContainerProps
>((props, ref) => {
    return (
        <div
            id={props.id}
            ref={ref}
            className={props.className}
            onClick={props.onClick}
            onDoubleClick={props.onDoubleClick}
            onMouseOver={props.onMouseOver}
            onMouseOut={props.onMouseOut}
            style={{
                display: 'flex',
                boxSizing: 'border-box',
                padding: 0,
                flexDirection: props.direction,
                alignItems: props.alignItems,
                justifyContent: props.justifyContent,
                flex: props.flex,
                overflowY: props.scrollY === true ? 'auto' : undefined,
                flexWrap: props.flexWrap,
                ...props.style,
            }}
            onDragOver={props.onDragOver}
            onDragEnd={props.onDragEnd}
            onDrop={props.onDrop}
            onDragLeave={props.onDragLeave}
        >
            {props.children}
        </div>
    )
})
