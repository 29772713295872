import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useDateRange, useUser } from '../../../hooks'

import {
    UpdateWorkOrderRequest,
    WorkorderDetailActionThunk,
    deleteWorkorder,
    getOrCreateWorkorderChannel,
    getMessageList,
    setMessageList,
} from '../../../store'

import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    Switch,
    useTheme,
    Badge,
    Tooltip,
} from '@material-ui/core'
import MessageIcon from '@material-ui/icons/Message'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'

import {
    Container,
    NumberInput,
    Selector,
    SideDrawer,
    StatusBadge,
    TransitionLog,
    WorkorderTransitionButtons,
    WorkorderTransitionLogPopver,
} from '../../../components'
import {
    Area,
    AreaConfig,
    getTotalPriceExpense,
    getUiString,
    IdentifiableNamedObject,
    ListVendor,
    ModelMap,
    Unit,
    UnitConfig,
    BaseWorkorder,
    WorkorderStatus,
    isWorkorderSafe,
    EDIT_WORKORDERS,
    DELETE_WORKORDERS,
    SEE_VENDOR_FINANCES,
    Schedule,
    activeStatuses,
} from '../../../models'

import { axiosInstance } from '../../../helpers'
import NumberFormat from 'react-number-format'
import { hasPermission } from '../../../models/Users/services'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ReactComponent as AddChangeOrder } from '../../../assets/icons/add-change-order.svg'
import { CreateChangeOrder } from './CreateChangeOrder'

interface ServiceAreaUIState {
    [areaId: number]: {
        include: boolean
    }
}

interface ServiceAreaProps {
    area: Area
    areaConfig?: AreaConfig
    checked: boolean
    locked: boolean
    onChange: () => void
}

const ServiceArea = (props: ServiceAreaProps) => {
    const { area, checked, onChange, locked, areaConfig } = props
    return (
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={onChange}
                    disabled={locked}
                />
            }
            label={
                <div>
                    <span>
                        {areaConfig?.name} ({area.area_label})
                    </span>
                </div>
            }
        />
    )
}

interface Props {
    workorder?: BaseWorkorder
    unit?: Unit
    unitConfig?: UnitConfig
    vendorList?: ListVendor[]
    areaConfigMap: ModelMap<AreaConfig>
    onClose: () => void
    openMessages: () => void
    updateWorkorder: (req: UpdateWorkOrderRequest) => WorkorderDetailActionThunk
    schedule?: Schedule
}

// const DRAWER_WIDTH = DEFAULT_DRAWER_WIDTH
const DRAWER_WIDTH = 330
const TITLE_HEIGHT = 66
const WORKORDER_RANGE_KEY = 'WORKORDER_RANGE_KEY'

export const WorkorderDetailDrawer = (props: Props) => {
    const {
        workorder,
        unit,
        onClose,
        openMessages,
        updateWorkorder,
        unitConfig,
        areaConfigMap,
        schedule,
    } = props

    const theme = useTheme()
    const { workspaceUser } = useUser()

    const { dateRange, setRange, setStartDate, setEndDate } = useDateRange(
        WORKORDER_RANGE_KEY,
        theme.palette.primary.main,
        true,
    )

    const [addPrice, setAddPrice] = useState<number | undefined>()
    const [customPrice, setCustomPrice] = useState<number | null>(null)
    const [priority, setPriority] = useState(false)

    const unitAreas =
        unit?.areas.filter(
            (area) => areaConfigMap[area.area_config]?.shows_on_schedule,
        ) ?? []

    const [vendor, setVendor] = useState({
        id: workorder?.vendor_id ?? -1,
        name: workorder?.vendor_name ?? 'unknown',
    })

    const dispatch = useAppDispatch()

    const [addChangeOrder, setAddChangeOrder] = useState(false)

    const [
        serviceAreaUIState,
        setServiceAreaUIState,
    ] = useState<ServiceAreaUIState>({})

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const popOverOpen = Boolean(anchorEl)

    const closePopover = () => {
        setAnchorEl(null)
    }

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    useEffect(() => {
        dispatch(setMessageList([]))
        if (workorder) {
            if (isWorkorderSafe(workorder)) {
                setRange({
                    startDate: new Date(workorder.startDate),
                    endDate: new Date(workorder.endDate),
                    key: WORKORDER_RANGE_KEY,
                    color: theme.palette.primary.main,
                })
                setPriority(workorder.priority)
                setVendor({
                    id: workorder.vendor_id,
                    name: workorder.vendor_name,
                })
            }

            const newSaUiState: ServiceAreaUIState = {}
            unitAreas.forEach((area) => {
                newSaUiState[area.id] = {
                    include: false,
                }
            })
            workorder.service_area_list.map((sa) => {
                newSaUiState[sa.area_id] = {
                    include: true,
                }
            })
            setServiceAreaUIState(newSaUiState)
            setCustomPrice(workorder.custom_price)
            setAddPrice(workorder.add_price)

            dispatch(getOrCreateWorkorderChannel(workorder.id)).then((res) => {
                dispatch(getMessageList({ channelId: res.data.id }))
            })
        } else {
            setVendor({ id: -1, name: '' })
            setPriority(false)
            setRange({
                startDate: new Date(),
                endDate: new Date(),
                key: WORKORDER_RANGE_KEY,
                color: theme.palette.primary.main,
            })
            setAddPrice(0)
            setCustomPrice(null)
            setServiceAreaUIState({})
        }
        // TODO: get channel details + workorder messages
    }, [workorder, unit])

    let vendorOptions: IdentifiableNamedObject[] = []
    if (workorder?.price_locked && isWorkorderSafe(workorder)) {
        vendorOptions = [
            { id: workorder.vendor_id, name: workorder.vendor_name },
        ]
    } else if (props.vendorList) {
        vendorOptions = props.vendorList.filter((vnd) => {
            return vnd.services.find((srv) => srv.id === workorder?.service_id)
        })
    }

    let messageBadgeCount = 0
    if ((workorder?.message_count ?? 0) > 0) {
        messageBadgeCount = workorder!.message_count
    }
    if ((workorder?.unread_count ?? 0) > 0) {
        messageBadgeCount = workorder!.unread_count ?? 0
    }

    const CANNOT_EDIT_WORKORDER = !hasPermission(workspaceUser, EDIT_WORKORDERS)

    return (
        <SideDrawer
            width={DRAWER_WIDTH}
            open={workorder !== undefined}
            handleClose={onClose}
            title={
                <Container
                    style={{
                        flexDirection: 'column',
                        width: '100%',
                        margin: theme.spacing(1),
                        maxHeight: TITLE_HEIGHT,
                        minHeight: TITLE_HEIGHT,
                    }}
                >
                    <Container
                        style={{
                            flex: 1,
                            alignItems: 'center',
                            ...theme.typography.h6,
                        }}
                    >
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {workorder?.service_name}
                        </span>
                        {hasPermission(workspaceUser, SEE_VENDOR_FINANCES) && (
                            <NumberFormat
                                prefix="$"
                                thousandSeparator
                                value={
                                    workorder
                                        ? getTotalPriceExpense(workorder)
                                        : 0
                                }
                                displayType="text"
                                style={{
                                    marginLeft: theme.spacing(2),
                                    fontWeight:
                                        theme.typography.fontWeightLight,
                                }}
                            />
                        )}
                        <div style={{ flex: 1 }} />
                        <StatusBadge
                            text={getUiString(
                                workorder?.status ?? WorkorderStatus.ASSIGNED,
                            )}
                            customStyle={{
                                container: {
                                    backgroundColor:
                                        theme.palette[
                                            workorder?.status ?? 'primary'
                                        ].main,
                                    marginLeft: theme.spacing(1),
                                },
                            }}
                        />
                    </Container>

                    <Container
                        style={{
                            ...theme.typography.caption,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightLight,
                                textOverflow: 'ellipsis',
                                maxWidth: DRAWER_WIDTH / 2,
                                overflow: 'hidden',
                                direction: 'rtl',
                                textAlign: 'left',
                            }}
                        >
                            {unit?.folder.path}
                            {unit?.folder.name}
                        </span>
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                                marginLeft: theme.spacing(2),
                            }}
                        >
                            {unit?.name}
                        </span>
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightMedium,
                                marginLeft: theme.spacing(2),
                            }}
                        >
                            {unitConfig?.name ?? 'unknown'}
                        </span>
                    </Container>
                </Container>
            }
        >
            {workorder === undefined ? (
                <div />
            ) : (
                <>
                    <Container scrollY direction="column">
                        <Container
                            style={{
                                padding: theme.spacing(1),
                                alignItems: 'center',
                            }}
                        >
                            <Selector
                                customStyle={{
                                    formControl: {
                                        margin: theme.spacing(1, 0),
                                        flex: 1,
                                    },
                                }}
                                disabled={
                                    workorder.price_locked ||
                                    CANNOT_EDIT_WORKORDER ||
                                    !schedule?.active
                                }
                                currentValue={vendor.id}
                                label="Vendor"
                                data={vendorOptions}
                                getDisplayString={(v) => v.name}
                                onChange={(e) => {
                                    const vendorId = Number(e.target.value)
                                    if (typeof vendorId === 'number') {
                                        const newVendor = props.vendorList?.find(
                                            (vnd) => vnd.id === vendorId,
                                        )
                                        if (newVendor !== undefined) {
                                            setVendor({
                                                id: newVendor.id,
                                                name: newVendor.name,
                                            })
                                        }
                                    }
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={priority}
                                        color="primary"
                                        onChange={() => setPriority(!priority)}
                                        disabled={
                                            CANNOT_EDIT_WORKORDER ||
                                            !schedule?.active ||
                                            workorder.price_locked
                                        }
                                    />
                                }
                                labelPlacement="top"
                                label="Priority"
                            />

                            <IconButton onClick={openMessages}>
                                <Badge
                                    badgeContent={messageBadgeCount}
                                    color={
                                        (workorder.unread_count ?? 0) > 0
                                            ? 'secondary'
                                            : 'primary'
                                    }
                                >
                                    <MessageIcon fontSize="large" />
                                </Badge>
                            </IconButton>
                        </Container>

                        <Container style={{ padding: theme.spacing(1) }}>
                            <KeyboardDatePicker
                                value={dateRange.startDate}
                                onChange={(date) =>
                                    setStartDate(date ?? new Date())
                                }
                                format="MM/dd/yyyy"
                                inputVariant="outlined"
                                label="Start Date"
                                disabled={
                                    !schedule?.active || workorder.price_locked
                                }
                            />

                            <KeyboardDatePicker
                                value={dateRange.endDate}
                                onChange={(date) =>
                                    setEndDate(date ?? new Date())
                                }
                                format="MM/dd/yyyy"
                                inputVariant="outlined"
                                style={{ marginLeft: theme.spacing(1) }}
                                label="End Date"
                                disabled={
                                    !schedule?.active || workorder.price_locked
                                }
                            />
                        </Container>
                        <Divider />

                        <Container style={{ padding: theme.spacing(1) }}>
                            <FormGroup>
                                {unitAreas
                                    .slice(0, unitAreas.length / 2)
                                    .map((area) => {
                                        return (
                                            <ServiceArea
                                                key={`WO_DETAIL_SA_${area.id}`}
                                                locked={
                                                    workorder.price_locked ||
                                                    CANNOT_EDIT_WORKORDER ||
                                                    !schedule?.active
                                                }
                                                area={area}
                                                areaConfig={
                                                    areaConfigMap[
                                                        area.area_config
                                                    ]
                                                }
                                                checked={
                                                    serviceAreaUIState[area.id]
                                                        ?.include === true
                                                }
                                                onChange={() =>
                                                    setServiceAreaUIState({
                                                        ...serviceAreaUIState,
                                                        [area.id]: {
                                                            include: !serviceAreaUIState[
                                                                area.id
                                                            ].include,
                                                        },
                                                    })
                                                }
                                            />
                                        )
                                    })}
                            </FormGroup>

                            <FormGroup>
                                {unitAreas
                                    .slice(unitAreas.length / 2)
                                    .map((area) => {
                                        return (
                                            <ServiceArea
                                                key={`WO_DETAIL_SA_${area.id}`}
                                                locked={
                                                    workorder.price_locked ||
                                                    CANNOT_EDIT_WORKORDER ||
                                                    !schedule?.active
                                                }
                                                area={area}
                                                areaConfig={
                                                    areaConfigMap[
                                                        area.area_config
                                                    ]
                                                }
                                                checked={
                                                    serviceAreaUIState[area.id]
                                                        ?.include === true
                                                }
                                                onChange={() =>
                                                    setServiceAreaUIState({
                                                        ...serviceAreaUIState,
                                                        [area.id]: {
                                                            include: !serviceAreaUIState[
                                                                area.id
                                                            ].include,
                                                        },
                                                    })
                                                }
                                            />
                                        )
                                    })}
                            </FormGroup>
                        </Container>
                        {hasPermission(workspaceUser, SEE_VENDOR_FINANCES) && (
                            <Container style={{ padding: theme.spacing(1) }}>
                                <NumberInput
                                    prefix={'$'}
                                    variant="outlined"
                                    label="Add Price"
                                    value={addPrice}
                                    disabled={
                                        CANNOT_EDIT_WORKORDER ||
                                        !schedule?.active ||
                                        workorder.price_locked
                                    }
                                    onChange={(e) => {
                                        if (e.target.value === '') {
                                            setAddPrice(undefined)
                                        } else {
                                            setAddPrice(Number(e.target.value))
                                        }
                                    }}
                                    style={{ marginRight: theme.spacing(2) }}
                                />

                                <NumberInput
                                    prefix={'$'}
                                    variant="outlined"
                                    label="Custom Price"
                                    value={customPrice}
                                    disabled={
                                        CANNOT_EDIT_WORKORDER ||
                                        !schedule?.active ||
                                        workorder.price_locked
                                    }
                                    onChange={(e) => {
                                        if (e.target.value === '') {
                                            setCustomPrice(null)
                                        } else {
                                            setCustomPrice(
                                                Number(e.target.value),
                                            )
                                        }
                                    }}
                                />
                            </Container>
                        )}
                    </Container>

                    <Divider />

                    <Container
                        style={{
                            padding: theme.spacing(1),
                            flexDirection: 'column',
                        }}
                    >
                        <Container style={{ alignItems: 'center' }}>
                            <span
                                style={{
                                    ...theme.typography.h6,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Last Transition
                            </span>

                            <div style={{ flex: 1 }} />

                            <Tooltip title="View All">
                                <IconButton onClick={openPopover}>
                                    <MenuOpenIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Container>

                        {/* Display the most recent transition */}
                        {workorder.transition_logs.length > 0 && (
                            <TransitionLog
                                log={
                                    workorder.transition_logs[
                                        workorder.transition_logs.length - 1
                                    ]
                                }
                                theme={theme}
                            />
                        )}
                    </Container>

                    <Divider />

                    <WorkorderTransitionButtons
                        workorder={workorder}
                        user={workspaceUser}
                        disabled={!schedule?.active}
                    />

                    {activeStatuses.includes(workorder.status) && (
                        <Container
                            style={{
                                padding: theme.spacing(1),
                                flexDirection: 'column',
                            }}
                        >
                            <Container style={{ alignItems: 'center' }}>
                                <span
                                    style={{
                                        ...theme.typography.h6,
                                        fontWeight:
                                            theme.typography.fontWeightBold,
                                    }}
                                >
                                    Add Change Order
                                </span>

                                <Container
                                    style={{
                                        marginLeft: theme.spacing(2),
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton
                                        onClick={() => setAddChangeOrder(true)}
                                    >
                                        <AddChangeOrder />
                                    </IconButton>
                                </Container>
                            </Container>
                            {addChangeOrder && (
                                <CreateChangeOrder
                                    setAddChangeOrder={setAddChangeOrder}
                                    unitId={workorder.unit_id}
                                    workorder={workorder}
                                    onClose={onClose}
                                />
                            )}
                        </Container>
                    )}

                    {/* Footer */}
                    <div style={{ flex: 1 }} />
                    <Divider />
                    <Container
                        style={{
                            padding: theme.spacing(2),
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="outlined"
                            style={{ marginRight: theme.spacing(2) }}
                            disabled={
                                !hasPermission(
                                    workspaceUser,
                                    DELETE_WORKORDERS,
                                ) || !schedule?.active
                            }
                            onClick={() => {
                                if (
                                    confirm(
                                        'Caution! This will permanently delete this workorder.',
                                    )
                                ) {
                                    axiosInstance
                                        .delete(`workorder/${workorder.id}/`)
                                        .then(() => {
                                            dispatch(
                                                deleteWorkorder(workorder.id),
                                            )
                                            toast.success('Workorder Deleted')
                                            props.onClose()
                                        })
                                }
                            }}
                        >
                            Delete
                        </Button>
                        <div style={{ flex: 1 }} />
                        <Button
                            variant="outlined"
                            style={{ marginRight: theme.spacing(2) }}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            disabled={
                                CANNOT_EDIT_WORKORDER ||
                                !schedule?.active ||
                                workorder.price_locked
                            }
                            onClick={() => {
                                const serviceAreas = Object.keys(
                                    serviceAreaUIState,
                                ).reduce<number[]>((prev, areaId) => {
                                    const aid = parseInt(areaId)
                                    if (serviceAreaUIState[aid].include) {
                                        return prev.concat(aid)
                                    }
                                    return prev
                                }, [])

                                const updateWoReq: UpdateWorkOrderRequest = {
                                    workorderId: workorder.id,
                                    body: {
                                        add_price: addPrice,
                                        start_date:
                                            dateRange.startDate?.toISOString() ??
                                            undefined,
                                        end_date:
                                            dateRange.endDate?.toISOString() ??
                                            undefined,
                                        service_areas: serviceAreas,
                                        priority: priority,
                                        vendor: vendor.id,
                                    },
                                }

                                if (customPrice !== null) {
                                    updateWoReq.body.custom_price = customPrice
                                }
                                updateWorkorder(updateWoReq).then(() => {
                                    onClose()
                                    toast.success('Workorder Updated!')
                                })
                            }}
                        >
                            Save
                        </Button>
                    </Container>

                    <WorkorderTransitionLogPopver
                        open={popOverOpen}
                        anchorEl={anchorEl}
                        // anchorOrigin={{
                        //     horizontal: 'left',
                        //     vertical: 'bottom',
                        // }}
                        transitionLogs={workorder.transition_logs}
                        closePopover={closePopover}
                        workorder={workorder}
                    />
                </>
            )}
        </SideDrawer>
    )
}
