import React, { useRef } from 'react'

import { makeStyles, Paper, Theme } from '@material-ui/core'

import { BaseWorkorder, ListVendor, User, WorkorderStatus } from '../../models'
import { WorkorderResponse } from '../../store'
import { JobCard } from './JobCard'
import { Container } from '../../components'
import { JbDrawerId } from './JobBoard'

interface Props {
    dateKey: string
    vendor: ListVendor
    workorders: WorkorderResponse[]
    theme: Theme
    draggedWorkorder: WorkorderResponse | null
    user?: User
    isLastColumn: boolean
    transitionWorkorder: (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
    ) => Promise<void>
    openDetailDrawer: (
        workorder: WorkorderResponse,
        drawerId: JbDrawerId,
    ) => void
    onDragStart: (e: React.DragEvent, workorderId: string) => void
    onDrop: (
        workorderId: string,
        newVendorId: number,
        newDateKey: string,
    ) => void
    onDragOver: (e: React.DragEvent, columnElement: HTMLElement | null) => void
    navigateToScheduleDetail: (scheduleId: number) => void
}

const useStyles = makeStyles((theme) => ({
    cell: {
        border: `1px solid ${theme.palette.divider}`,
        height: '100%',
        minWidth: 310,
        maxWidth: 310,
        borderRadius: 0,
        justifyContent: 'center',
        minHeight: 50,
    },
    dropZone: {
        height: '100%',
        border: '2px dashed transparent',
        transition: 'border-color 0.2s ease',
        '&.drag-over': {
            borderColor: theme.palette.primary.main,
        },
    },
    dragItem: {
        margin: theme.spacing(1, 0),
        cursor: 'move',
    },
    dragDisabled: {
        cursor: 'not-allowed',
    },
    invalidDropTarget: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
}))

export const VendorCell = (props: Props) => {
    const {
        dateKey,
        vendor,
        workorders,
        theme,
        user,
        draggedWorkorder,
        isLastColumn,
        transitionWorkorder,
        openDetailDrawer,
        onDragStart,
        onDrop,
        onDragOver,
        navigateToScheduleDetail,
    } = props

    const classes = useStyles()

    const columnRef = useRef<HTMLDivElement>(null)

    const isValidDropTarget = draggedWorkorder
        ? vendor.services.some(
              (service) => service.id === draggedWorkorder.service_id,
          )
        : true

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault()
        if (isValidDropTarget) {
            e.currentTarget.classList.add('drag-over')
            onDragOver(e, columnRef.current)
        }
    }

    const handleDragLeave = (e: React.DragEvent) => {
        e.currentTarget.classList.remove('drag-over')
    }

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault()
        e.currentTarget.classList.remove('drag-over')
        if (isValidDropTarget) {
            const workorderId = e.dataTransfer.getData('text/plain')
            onDrop(workorderId, vendor.id, dateKey)
        }
    }

    return (
        <Paper
            className={`${classes.cell} ${
                !isValidDropTarget ? classes.invalidDropTarget : ''
            }`}
            style={{ marginRight: isLastColumn ? 0 : theme.spacing(2) }}
            elevation={3}
        >
            <Container
                className={classes.dropZone}
                onDragOver={(e) => {
                    e.preventDefault()
                    e.currentTarget.classList.add('drag-over')
                    handleDragOver(e)
                }}
                onDragLeave={(e) => {
                    e.currentTarget.classList.remove('drag-over')
                    handleDragLeave(e)
                }}
                onDrop={(e) => handleDrop(e)}
                style={{ flexDirection: 'column' }}
            >
                {workorders.map((workorder) => (
                    <div
                        key={`WORKORDER_CARD_${workorder.id}_${vendor.id}`}
                        className={`${classes.dragItem} ${
                            workorder.price_locked ? classes.dragDisabled : ''
                        }`}
                        draggable={!workorder.price_locked}
                        onDragStart={(e) =>
                            !workorder.price_locked &&
                            onDragStart(e, workorder.id.toString())
                        }
                    >
                        <JobCard
                            workorder={workorder}
                            theme={theme}
                            user={user}
                            transitionWorkorder={transitionWorkorder}
                            openMessageDrawer={() => {
                                openDetailDrawer(workorder, JbDrawerId.message)
                            }}
                            openUnitNotes={() => {
                                openDetailDrawer(
                                    workorder,
                                    JbDrawerId.unitNotes,
                                )
                            }}
                            openEditModal={() => {
                                openDetailDrawer(workorder, JbDrawerId.edit)
                            }}
                            navigateToScheduleDetail={navigateToScheduleDetail}
                        />
                    </div>
                ))}
            </Container>
        </Paper>
    )
}
