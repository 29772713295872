import React from 'react'
import { JobBoard } from './JobBoard'
import { useWorkorderState, WorkorderContext } from '../../contexts'

export const JobBoardRoot = () => {
    const workorderState = useWorkorderState()

    return (
        <WorkorderContext.Provider value={workorderState}>
            <JobBoard />
        </WorkorderContext.Provider>
    )
}
