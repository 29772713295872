import React from 'react'

import { toast } from 'react-toastify'

import { Badge, IconButton, Tooltip, useTheme } from '@material-ui/core'

import { ChatBubbleOutline, ReportProblem } from '@material-ui/icons'

import {
    BaseWorkorder,
    User,
    getServiceImageDetails,
    WorkorderStatus,
    RFPType,
    WorkorderType,
} from '../../models'

import {
    Attachment,
    Container,
    WorkorderStatusBadge,
    WorkorderTransitionButtons,
} from '../../components'
import { mix_hexes } from '../../helpers'
import { _useFinderSelection } from '../../hooks'

import { useStyles } from '../../styles'

import {
    DRAWER_W,
    LEFT_TOOL_BAR_W,
    LEFT_COL_W,
    MID_COL_W,
    PADDING,
} from './contants'

import { ReactComponent as EZTurnLogo } from '../../assets/Logo.svg'
import { WorkorderInfoPopover } from '../../components/WorkorderLib/WorkorderInfoPopover'

interface ActionItemRowProps {
    workorder: BaseWorkorder
    onClick: () => void
    isSelected: boolean
    openMessageDrawer: () => void
    drawerOpen?: boolean
    EZNowSelected?: boolean
    user?: User
    transitionWorkorder: (
        workorder: BaseWorkorder,
        status: WorkorderStatus,
    ) => Promise<void>
}

export const ActionItemRow = (props: ActionItemRowProps) => {
    const {
        workorder,
        isSelected,
        openMessageDrawer,
        drawerOpen,
        user,
        EZNowSelected,
    } = props

    const theme = useTheme()
    const classes = useStyles()

    const location = `${workorder.path ?? ''}${workorder.folder_name ?? ''}`

    const unitName = workorder.unit_name ?? ''

    const priorityIcon =
        (workorder as any).priority === 1 ? (
            <Tooltip title="Priority">
                <ReportProblem color="secondary" fontSize="small" />
            </Tooltip>
        ) : null

    const openDrawerAdjustment = drawerOpen ? `- ${DRAWER_W}px` : ''
    const rowWidthCalc = `calc(100vw - ${LEFT_COL_W} - ${LEFT_COL_W} - ${PADDING} - ${PADDING} - ${LEFT_TOOL_BAR_W} ${openDrawerAdjustment})`

    let backgroundColor = ''
    if (isSelected) {
        backgroundColor = theme.palette.grey[300]
    }
    if (EZNowSelected) {
        backgroundColor = backgroundColor = mix_hexes(
            theme.palette.secondary.main,
            '#ffffff',
        )
    }

    const serviceIcon = getServiceImageDetails(workorder.service_id ?? -1)

    let serviceComponent: JSX.Element | null = <span>No Service</span>
    if (serviceIcon.src !== null) {
        serviceComponent = (
            <Container>
                <img
                    src={serviceIcon.icon}
                    style={{
                        maxHeight: 25,
                        maxWidth: 27,
                        minHeight: 25,
                        minWidth: 27,
                        marginRight: theme.spacing(1),
                    }}
                />
                <span>{workorder.service_name ?? ''}</span>
            </Container>
        )
    }

    let EZNowIcon: JSX.Element | null = null
    if (workorder.active_rfp && workorder.rfp_type === RFPType.EZNOW) {
        EZNowIcon = (
            <Tooltip title="EZNow">
                <EZTurnLogo style={{ height: 30, width: 30 }} />
            </Tooltip>
        )
    }

    let workorderInfo: JSX.Element | null = null
    if (workorder.info) {
        workorderInfo = (
            <WorkorderInfoPopover workorder={workorder} theme={theme} />
        )
    }

    return (
        <Container
            style={{
                margin: theme.spacing(1),
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[600]}`,
                minHeight: 240,
                maxHeight: 240,
                overflowX: 'auto',
                cursor: 'pointer',
                backgroundColor: backgroundColor,
            }}
            className={classes.hoverGrey200}
            onClick={props.onClick}
        >
            {/* Left Column */}
            <Container
                direction="column"
                style={{
                    padding: theme.spacing(2),
                    fontWeight: theme.typography.fontWeightBold,
                    ...theme.typography.body1,
                    minWidth: LEFT_COL_W,
                    maxWidth: LEFT_COL_W,
                }}
            >
                <span
                    style={{
                        fontWeight: theme.typography.fontWeightBold,
                        ...theme.typography.h6,
                    }}
                >
                    {workorder.title}
                </span>

                <span
                    style={{ fontWeight: theme.typography.fontWeightRegular }}
                >
                    {location}
                </span>
                <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                    {unitName}
                </span>

                {serviceComponent}

                <WorkorderTransitionButtons
                    workorder={workorder}
                    user={user}
                    style={{
                        margin: 0,
                        justifyContent: 'flex-start',
                        marginTop: theme.spacing(1),
                    }}
                    handleTransition={(e, workorder, status) => {
                        e.stopPropagation()
                        props
                            .transitionWorkorder(workorder, status)
                            .then(() => toast.success('Success'))
                    }}
                />

                <div style={{ flex: 1 }} />

                <Container
                    style={{
                        alignItems: 'center',
                    }}
                >
                    <WorkorderStatusBadge workorder={workorder} theme={theme} />

                    <Container flex={1} />
                    <Container style={{ width: '30px' }}>{EZNowIcon}</Container>
                    <Container style={{ width: '45px' }}>
                        {priorityIcon}
                    </Container>
                    <Container style={{ width: '45px' }}>
                        {workorderInfo}
                    </Container>
                    <Container>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                openMessageDrawer()
                            }}
                        >
                            <Badge
                                badgeContent={workorder.message_count}
                                color={
                                    (workorder.unread_count ?? 0) > 0
                                        ? 'primary'
                                        : 'secondary'
                                }
                            >
                                <ChatBubbleOutline />
                            </Badge>
                        </IconButton>
                    </Container>
                </Container>
            </Container>

            {/* Middle Column */}
            <Container
                style={{
                    borderLeft: `1px solid ${theme.palette.grey[600]}`,
                    borderRight: `1px solid ${theme.palette.grey[600]}`,
                    flex: 1,
                    padding: theme.spacing(2),
                    minWidth: MID_COL_W,
                    maxWidth: MID_COL_W,
                    overflow: 'auto',
                }}
                direction="column"
            >
                <Container
                    style={{
                        marginBottom: theme.spacing(2),
                        fontWeight: 600,
                        fontSize: '18px',
                    }}
                >
                    {workorder.vendor_name ?? workorder.user_name ?? 'My Team'}
                </Container>
                {workorder.service_request &&
                    workorder.type === WorkorderType.SERVICE_REQUEST && (
                        <span
                            style={{
                                fontSize: '14px',
                                color: theme.palette.text.secondary,
                            }}
                        >
                            Tenant: {workorder.service_request.tenant || 'N/A'}
                        </span>
                    )}
            </Container>

            {/* Right Column */}
            <Container
                style={{
                    padding: theme.spacing(1),
                    paddingBottom: 0,
                    paddingRight: 0,
                    alignItems: 'center',
                    overflowX: 'auto',
                    maxWidth: rowWidthCalc,
                }}
            >
                {workorder.attachment_list?.map((attachment) => {
                    return (
                        <div key={`ATTACHMENT_${attachment.id}`} style={{}}>
                            <Attachment
                                attachment={attachment.file}
                                uploaded={attachment.uploaded}
                                theme={theme}
                            />
                        </div>
                    )
                })}
            </Container>
        </Container>
    )
}
