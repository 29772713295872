import {
    Area,
    User,
    IdentifiableNamedObject,
    Inventory,
    EntrataWorkOrderConfig,
    Service,
    IdentifiableObject,
    Lease,
    Message,
    Attachment,
} from '..'
import { WorkorderResponse } from '../../store'

export enum ActionItemStatus {
    STAGED = 'STAGED',
    REJECTED = 'REJECTED',
    ASSIGNED = 'ASSIGNED',
    IN_PROGRESS = 'IN_PROGRESS',
    PAUSE = 'PAUSE',
    COMPLETE = 'COMPLETE',
}

export enum ActionItemSyncStatus {
    NOT_SYNCED = 'NOT_SYNCED',
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS',
}

export interface ActionItem {
    id: number
    status: ActionItemStatus
    sync_status: ActionItemSyncStatus
    title: string
    folder_location?: FolderLocation
    unit_location?: IdentifiableNamedObject
    service?: IdentifiableNamedObject
    area?: Area
    inventory?: Inventory
    priority?: boolean
    who_type: AssignmentWhoType
    assigned_to: string
    assigned_to_id: number
    assigned_by: User
    unread_count: number
    start_date?: string
    inventory_inspection: number | null
    channel: number
    message_count: number
    entrata_workorder_config: EntrataWorkOrderConfig | null
    service_request: number | null
    attachments: Attachment[]
}

export type AssignmentWhoType = 'WHO_COMPANY' | 'WHO_USER'

interface FolderLocation extends IdentifiableNamedObject {
    path: string
}

export interface ServiceRequestCategory {
    id: number
    name: string
    services: Service[]
}

export enum ServiceRequestStatus {
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
    ASSIGNED = 'ASSIGNED',
    IN_PROGRESS = 'IN_PROGRESS',
    PAUSE = 'PAUSE',
    COMPLETE = 'COMPLETE',
}

export interface ServiceRequest extends IdentifiableObject {
    lease: number
    tenant: string
    folder_name: string
    path: string
    unit_name: string
    description: string
    created_date: string
    image: string | null
    workorder: WorkorderResponse | null
}
