import React, { useState } from 'react'
import { useAppDispatch, useUser } from '../../../../hooks'
import {
    Breadcrumbs,
    Button,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Theme,
    Typography,
    createStyles,
    makeStyles,
    useTheme,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Container } from '../../../../components'
import { PhotoCamera } from '@material-ui/icons'
import { axiosInstance } from '../../../../helpers'
import { toast } from 'react-toastify'
import { setWorkSpaceUser } from '../../../../store'
import LoopIcon from '@material-ui/icons/Loop'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fileUploadBtn: {
            position: 'absolute',
            right: '-5%',
            bottom: '-10%',
            color: 'rgb(255, 255, 255)',
            background: 'rgb(44, 128, 255)',
            padding: '4px',
            border: '5px solid #fafafa',
            fontSize: '0.5rem',
            '& .MuiSvgIcon-root': {
                height: '1.2em',
                width: '1.2em',
                '& path': {
                    stroke: '#000',
                },
                '& circle': {
                    stroke: '#FFF',
                    strokeWidth: '10px',
                },
            },
        },
        uploadInput: {
            display: 'none',
        },
        rotateIcon: {
            animation: 'spin 01s linear infinite',
        },
    }),
)

export const PropertyDetails = () => {
    const classes = useStyles()
    const { workspaceUser } = useUser()
    const theme = useTheme()

    const dispatch = useAppDispatch()

    const [url, setURL] = useState(workspaceUser?.active_workspace.url)
    const [profilePic, setProfilePic] = useState<File | null>(null)
    const [profilePicString, setProfilePicString] = useState(
        workspaceUser?.active_workspace.profile_picture,
    )
    const [loading, setLoading] = useState(false)

    const [allowServiceRequest, setAllowServiceRequest] = useState(
        workspaceUser?.active_workspace.allow_service_request ? true : false,
    )

    let filePreview =
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQ5srpTZoAXWINllDurGscx_Pqg_foehqAkQ&usqp=CAU'

    if (profilePic) {
        filePreview = URL.createObjectURL(profilePic)
    } else if (profilePicString) {
        filePreview = profilePicString
    }

    const onSave = () => {
        const body = new FormData()

        if (url) {
            body.append('url', url)
        }

        if (profilePic) {
            body.append('profile_picture', profilePic)
        }

        body.append(
            'allow_service_request',
            allowServiceRequest ? 'True' : 'False',
        )

        setLoading(true)
        axiosInstance
            .patch(
                `company/apartment/${workspaceUser?.active_workspace.id}/`,
                body,
            )
            .then((res) => {
                const updatedUser = workspaceUser
                if (updatedUser?.active_workspace) {
                    updatedUser.active_workspace = res.data
                    dispatch(setWorkSpaceUser(updatedUser))
                }
                toast.success(`${workspaceUser?.active_workspace.name} updated`)
            })
            .catch((e) => {
                toast.error(e.response.data.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    color="inherit"
                    to="/settings"
                    style={{
                        color: '#8A8A8A',
                        fontSize: '15px',
                        textDecoration: 'none',
                    }}
                >
                    Settings
                </Link>
                <Typography
                    color="textPrimary"
                    style={{
                        color: '#8A8A8A',
                        fontSize: '15px',
                        textDecoration: 'none',
                    }}
                >
                    Property Details
                </Typography>
            </Breadcrumbs>
            <h2
                style={{
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '40px',
                    lineHeight: '37px',
                    marginBottom: '15px',
                    marginTop: '5px',
                    color: '#2F2E41',
                }}
            >
                Property Details
            </h2>
            <Container direction="column">
                <Container style={{ alignItems: 'center' }}>
                    <div
                        style={{
                            width:
                                profilePic || profilePicString
                                    ? '300px'
                                    : '100px',
                            height:
                                profilePic || profilePicString
                                    ? '200px'
                                    : '100px',
                            display: 'inline-flex',
                            position: 'relative',
                        }}
                    >
                        <img src={filePreview} alt="" width={'100%'} />
                        <input
                            accept="image/png, image/jpeg, image/jpg"
                            className={classes.uploadInput}
                            id="icon-button-file"
                            type="file"
                            onChange={(event) => {
                                if (event.target.files) {
                                    setProfilePic(event.target.files[0])
                                }
                            }}
                        />
                        <label htmlFor="icon-button-file">
                            <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                className={classes.fileUploadBtn}
                                style={{
                                    color: '#FFF',
                                    background: '#2C80FF',
                                    padding: '4px',
                                }}
                            >
                                <PhotoCamera />
                            </IconButton>
                        </label>
                    </div>

                    <Container
                        style={{
                            fontWeight: 600,
                            fontSize: '20px',
                            marginLeft: theme.spacing(3),
                        }}
                    >
                        {workspaceUser?.active_workspace.name}
                    </Container>
                </Container>
                <Container
                    style={{
                        fontSize: '12px',
                        color: theme.palette.grey[500],
                        marginTop: theme.spacing(1),
                    }}
                >
                    Recommend Image Size: 1920 x 1080 px
                </Container>
                <Container
                    style={{ marginTop: theme.spacing(2) }}
                    direction="column"
                >
                    <span
                        style={{
                            fontWeight: 500,
                            fontSize: '16px',
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Website URL:
                    </span>
                    <TextField
                        variant="outlined"
                        value={url}
                        onChange={(e) => setURL(e.target.value)}
                        style={{
                            width: '400px',
                        }}
                        size="small"
                    />
                </Container>
                <Container style={{ marginTop: theme.spacing(2) }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={allowServiceRequest}
                                color="primary"
                                onChange={(e) => {
                                    setAllowServiceRequest(!allowServiceRequest)
                                }}
                            />
                        }
                        labelPlacement="end"
                        label="Allow Service Request"
                        style={{ marginTop: theme.spacing(2) }}
                    />
                </Container>
                <Container>
                    <Button
                        variant="contained"
                        style={{
                            marginTop: theme.spacing(4),
                            backgroundColor: '#008C85',
                            color: 'white',
                            textTransform: 'none',
                            cursor: 'pointer',
                        }}
                        onClick={() => onSave()}
                    >
                        {loading ? (
                            <LoopIcon className={classes.rotateIcon} />
                        ) : (
                            'Save'
                        )}
                    </Button>
                </Container>
            </Container>
        </>
    )
}
