import React, { useEffect, useMemo, useRef, useState } from 'react'

import moment from 'moment'

import {
    CircularProgress,
    makeStyles,
    Modal,
    Paper,
    Slide,
    useTheme,
} from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import {
    Container,
    CreateWorkorderForm,
    FilterChips,
    MessageForm,
    SideDrawer,
    SideDrawerContainer,
    WorkorderEditForm,
    WorkorderFilterForm,
} from '../../components'
import {
    _useFinderSelection,
    useAptConfig,
    useCompany,
    useRootInfrastructure,
    useSchedule,
    useService,
    useUser,
} from '../../hooks'
import { workorderContext } from '../../contexts'
import {
    CreateWorkOrderRequest,
    FinderLocationSelection,
    FinderSelectionMode,
    UpdateWorkOrderRequest,
    WorkorderResponse,
} from '../../store'
import { JobBoardHeader } from './JobBoardHeader'
import { eachDayOfInterval, format, isToday } from 'date-fns'
import { VendorCell } from './VendorCell'
import { VendorHeaderCell } from './VendorHeaderCell'
import { toast } from 'react-toastify'
import { activeStatuses, ListVendor } from '../../models'
import { useServiceRequest } from '../../hooks/useServiceRequest'
import { PendingServiceRequestView } from './PendingServiceRequestView'
import { resolveRoute, Routes } from '../../helpers'
import { useHistory } from 'react-router-dom'
import { PendingWorkView } from './PendingWorkView'

const useStyles = makeStyles((theme) => ({
    jobBoardContainer: {
        height: '100%',
        overflow: 'auto',
        position: 'relative',
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 'max-content',
    },
    headerRow: {
        display: 'flex',
        position: 'sticky',
        top: 0,
        zIndex: 3,
        backgroundColor: theme.palette.background.paper,
    },
    dateColumn: {
        position: 'sticky',
        left: 0,
        zIndex: 2,
        backgroundColor: theme.palette.background.paper,
        boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
    },
    dateHeaderCell: {
        padding: theme.spacing(1, 1, 0.5, 1),
        minWidth: 120,
        maxWidth: 120,
        fontWeight: theme.typography.fontWeightBold,
    },
    dateCell: {
        padding: theme.spacing(0.5, 1),
        minWidth: 120,
        maxWidth: 120,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dateText: {
        ...theme.typography.subtitle1,
        fontWeight: theme.typography.fontWeightBold,
    },
    workorderCount: {
        ...theme.typography.caption,
        color: theme.palette.text.secondary,
    },
    todayCell: {
        backgroundColor: theme.palette.primary.light,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    vendorHeaderCell: {
        padding: theme.spacing(0, 1),
        maxWidth: 300,
        minWidth: 300,
        marginRight: theme.spacing(2),
    },
    workorderCell: {
        padding: theme.spacing(0, 1),
        minWidth: 300,
        maxWidth: 300,
        marginRight: theme.spacing(2),
    },
    row: {
        display: 'flex',
    },
}))

export const JobBoard = () => {
    const { getVendorList, vendorList, updateVendorServices } = useCompany()

    const { workspaceUser, userList, getUserList } = useUser()

    const {
        workorderList,
        filteredWorkorderList,
        workorderFilterState,
        loadingState,
        getWorkorderList,
        transitionWorkorder,
        updateWorkorderState,
        setWorkorderFilterState,
        getChipData,
        updateWorkorder,
        createWorkorders,
        bulkUpdateWorkorders,
    } = workorderContext()

    const { tree } = useRootInfrastructure(true)

    const { serviceList } = useService({ getServiceList: true, cleanUp: true })

    const { scheduleList, getScheduleList } = useSchedule()

    const {
        serviceRequestList,
        filteredServiceRequestList,
        serviceRequestFilterState,
        setServiceRequestFilterState,
        getServiceRequestList,
        createServiceRequestWorkorder,
    } = useServiceRequest()

    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const scrollIntervalRef = useRef<number | null>(null)

    const theme = useTheme()
    const classes = useStyles()

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)
    const [dateFilter, setDateFilter] = useState(THIS_WEEK)
    const [startDate, setStartDate] = useState<MaterialUiPickersDate>(
        moment().day(1).toDate(),
    )
    const [endDate, setEndDate] = useState<MaterialUiPickersDate>(
        moment().day(7).toDate(),
    )

    const [formState, setFormState] = useState<JbFormState>(closedJbFormState)

    const [vendorFilter, setVendorFilter] = useState(ALL)

    const [
        draggedWorkorder,
        setDraggedWorkorder,
    ] = useState<WorkorderResponse | null>(null)

    const finderSelection = _useFinderSelection({
        whichSelection: FinderLocationSelection.RootSelection,
        selectionMode: FinderSelectionMode.Recursive,
    })

    const {
        getAreaConfigMap,
        areaConfigList,
        getUnitConfigMap,
        unitConfigList,
        areaStatusConfigList,
    } = useAptConfig({
        unitConfigList: true,
        areaConfigList: true,
        areaStatusConfigList: true,
        cleanUp: true,
    })

    const dateRange = useMemo(
        () =>
            eachDayOfInterval({
                start: new Date(startDate?.toISOString() ?? ''),
                end: new Date(endDate?.toISOString() ?? ''),
            }),
        [startDate, endDate],
    )

    const getWorkorderData = (
        startDate: Date | MaterialUiPickersDate,
        endDate: Date | MaterialUiPickersDate,
    ) => {
        setIsLoading(true)
        startDate?.setHours(0, 0, 0)
        endDate?.setHours(23, 59, 59)
        getWorkorderList({
            params: {
                upper_bound_date: endDate?.toISOString(),
                lower_bound_date: startDate?.toISOString(),
                unassigned: true,
                behind_date_range_union: true,
            },
        }).then(() => setIsLoading(false))
    }

    useEffect(() => {
        const vendorPromise = getVendorList({
            params: { my_team: true },
        })

        const workorderPromise = getWorkorderList({
            params: {
                upper_bound_date: endDate
                    ? new Date(
                          endDate.getFullYear(),
                          endDate.getMonth(),
                          endDate.getDate(),
                          23,
                          59,
                          59,
                          999,
                      ).toISOString()
                    : undefined,
                lower_bound_date: startDate
                    ? new Date(
                          startDate.getFullYear(),
                          startDate.getMonth(),
                          startDate.getDate(),
                          0,
                          0,
                          0,
                          0,
                      ).toISOString()
                    : undefined,
                unassigned: true,
                behind_date_range_union: true,
            },
        })

        const schedulePromise = getScheduleList({ params: { active: true } })

        const userPromise = getUserList({ params: { my_team: true } })

        const serviceRequestPromise = getServiceRequestList({
            params: { no_workorder: true },
        })

        Promise.all([
            vendorPromise,
            workorderPromise,
            schedulePromise,
            userPromise,
            serviceRequestPromise,
        ]).finally(() => setIsLoading(false))

        // Clean up the interval on component unmount
        return () => {
            if (scrollIntervalRef.current) {
                clearInterval(scrollIntervalRef.current)
            }
        }
    }, [])

    const areaConfigMap = useMemo(() => {
        return getAreaConfigMap()
    }, [areaConfigList])

    const unitConfigMap = useMemo(() => {
        return getUnitConfigMap()
    }, [unitConfigList])

    const sortVendors = (
        vendors: ListVendor[],
        filteredWorkorderList: WorkorderResponse[],
        currentDate: string,
    ) => {
        const today = new Date()
        today.setHours(0, 0, 0, 0) // Set to start of day for accurate comparison

        return vendors.sort((vendorA, vendorB) => {
            const countWorkorders = (vendor: ListVendor) => {
                return filteredWorkorderList.filter((wo) => {
                    const woDate = new Date(wo.start_date)
                    woDate.setHours(0, 0, 0, 0) // Set to start of day for accurate comparison
                    const isCurrentDate = wo.start_date?.startsWith(currentDate)
                    const isOldActive =
                        woDate < today && activeStatuses.includes(wo.status)
                    return (
                        wo.vendor_id === vendor.id &&
                        (isCurrentDate || isOldActive)
                    )
                }).length
            }

            const aWorkorders = countWorkorders(vendorA)
            const bWorkorders = countWorkorders(vendorB)

            // Sort by number of workorders (descending)
            if (bWorkorders !== aWorkorders) {
                return bWorkorders - aWorkorders
            }

            // If workorder count is the same, sort by in-house status
            if (vendorA.in_house !== vendorB.in_house) {
                return vendorA.in_house ? -1 : 1 // In-house vendors come first
            }

            // If both criteria are the same, sort alphabetically by name
            return vendorA.name.localeCompare(vendorB.name)
        })
    }

    const filteredVendorList = useMemo(() => {
        return vendorList.filter((vendor) => {
            if (vendorFilter === ALL) {
                return vendor
            } else if (vendorFilter === IN_HOUSE) {
                return vendor.in_house
            } else {
                return !vendor.in_house
            }
        })
    }, [vendorFilter, vendorList])

    const sortedVendors = useMemo(() => {
        let currentDate = format(new Date(), 'yyyy-MM-dd')
        if (dateFilter !== THIS_WEEK) {
            currentDate = format(
                new Date(startDate?.toISOString() ?? ''),
                'yyyy-MM-dd',
            )
        }
        return sortVendors(
            filteredVendorList,
            filteredWorkorderList,
            currentDate,
        )
    }, [filteredVendorList, dateFilter, startDate, isLoading])

    const unassignedWorkorders = useMemo(() => {
        return (
            workorderList?.filter((wo) => {
                return (
                    wo.vendor_id === null &&
                    wo.user_id === null &&
                    wo.price_group_id === null
                )
            }) ?? []
        )
    }, [workorderList])

    const workorderMap = useMemo(() => {
        const map = new Map<string, WorkorderResponse[]>()
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        const todayString = format(today, 'yyyy-MM-dd')
        filteredWorkorderList?.forEach((wo) => {
            const woStartDate = new Date(wo.start_date)
            if (wo.vendor_id) {
                let dateKey = format(woStartDate, 'yyyy-MM-dd')

                if (activeStatuses.includes(wo.status) && woStartDate < today) {
                    dateKey = todayString
                }

                if (!map.has(dateKey)) {
                    map.set(dateKey, [])
                }

                map.get(dateKey)!.push(wo)
            }
        })
        return map
    }, [filteredWorkorderList])

    const openDetailDrawer = (
        workorder: WorkorderResponse,
        drawerId: JbDrawerId,
    ) => {
        setFormState({
            ...closedJbFormState,
            detailFormOpen: {
                workorder: workorder,
                drawerId: drawerId,
            },
        })
    }

    const openFilterDrawer = () => {
        setFormState({
            ...closedJbFormState,
            filterFormOpen: true,
        })
    }

    const handleDragStart = (e: React.DragEvent, workorderId: string) => {
        const workorder = filteredWorkorderList.find(
            (wo) => wo.id.toString() === workorderId,
        )
        if (workorder && !workorder.price_locked) {
            e.dataTransfer.setData('text/plain', workorderId)
            setDraggedWorkorder(workorder)
            startAutoScroll(e)
        } else {
            e.preventDefault()
        }
    }

    const startAutoScroll = (
        e: React.DragEvent,
        columnElement?: HTMLElement | null,
    ) => {
        // Clear any existing interval
        if (scrollIntervalRef.current) {
            clearInterval(scrollIntervalRef.current)
        }

        scrollIntervalRef.current = window.setInterval(() => {
            if (scrollContainerRef.current) {
                const container = scrollContainerRef.current
                const containerRect = container.getBoundingClientRect()
                const horizontalScrollSpeed = 5
                const verticalScrollSpeed = 5

                // Horizontal scrolling
                if (e.clientX > containerRect.right - 50) {
                    container.scrollLeft += horizontalScrollSpeed
                } else if (e.clientX < containerRect.left + 50) {
                    container.scrollLeft -= horizontalScrollSpeed
                }

                // Vertical scrolling
                if (columnElement) {
                    const columnRect = columnElement.getBoundingClientRect()
                    if (e.clientY > columnRect.bottom - 50) {
                        columnElement.scrollTop += verticalScrollSpeed
                    } else if (e.clientY < columnRect.top + 50) {
                        columnElement.scrollTop -= verticalScrollSpeed
                    }
                }
            }
        }, 16) // ~60fps
    }

    const stopAutoScroll = () => {
        if (scrollIntervalRef.current) {
            clearInterval(scrollIntervalRef.current)
            scrollIntervalRef.current = null
        }
    }

    const handleDrop = (
        workorderId: string,
        newVendorId: number,
        newDateKey: string,
    ) => {
        const workorder = workorderList?.find(
            (wo) => wo.id.toString() === workorderId,
        )

        const newVendor = vendorList.find((v) => v.id === newVendorId)

        let serviceValid = false
        if (newVendorId === -1) {
            serviceValid = true
        } else {
            serviceValid =
                newVendor?.services.some(
                    (service) => service.id === workorder?.service_id,
                ) ?? false
        }

        if (workorder && !workorder.price_locked && serviceValid) {
            // Parse the newDateKey
            const [year, month, day] = newDateKey.split('-').map(Number)

            // Create new Date objects from the original start_date and end_date
            const originalStartDate = new Date(workorder.start_date)
            const originalEndDate = new Date(workorder.end_date)

            // Check if original start_date and end_date have the same date
            const sameOriginalDate =
                originalStartDate.getFullYear() ===
                    originalEndDate.getFullYear() &&
                originalStartDate.getMonth() === originalEndDate.getMonth() &&
                originalStartDate.getDate() === originalEndDate.getDate()

            // Create a new Date object for start_date with the new date and original time
            const newStartDate = new Date(
                year,
                month - 1, // JavaScript months are 0-indexed
                day,
                originalStartDate.getHours(),
                originalStartDate.getMinutes(),
                originalStartDate.getSeconds(),
                originalStartDate.getMilliseconds(),
            )

            // Calculate the difference in days between the new and original start dates
            const daysDifference = Math.round(
                (newStartDate.getTime() - originalStartDate.getTime()) /
                    (1000 * 60 * 60 * 24),
            )

            let newEndDate
            if (sameOriginalDate) {
                // If original dates were the same, set new end_date to new date with original end time
                newEndDate = new Date(
                    year,
                    month - 1,
                    day,
                    originalEndDate.getHours(),
                    originalEndDate.getMinutes(),
                    originalEndDate.getSeconds(),
                    originalEndDate.getMilliseconds(),
                )
            } else {
                // If original dates were different, adjust the end date by the same number of days
                newEndDate = new Date(originalEndDate)
                newEndDate.setDate(newEndDate.getDate() + daysDifference)
            }

            const updatedWorkorder = {
                ...workorder,
                vendor_id: newVendorId !== -1 ? newVendorId : null,
                start_date: newStartDate.toISOString(),
                end_date: newEndDate.toISOString(),
            }

            // Update the workorder in your our local state
            updateWorkorderState(updatedWorkorder)

            // Update workorder on the backend
            const request: UpdateWorkOrderRequest = {
                workorderId: workorder.id,
                body: {
                    start_date: newStartDate.toISOString(),
                    end_date: newEndDate.toISOString(),
                    assign_to_id: newVendorId,
                    assign_to_type: 'vendor',
                },
            }

            updateWorkorder(request).catch(() =>
                updateWorkorderState(workorder),
            )
        }

        setDraggedWorkorder(null)
        stopAutoScroll()
    }

    const handleDragOver = (
        e: React.DragEvent,
        columnElement: HTMLElement | null,
    ) => {
        e.preventDefault()
        startAutoScroll(e, columnElement)
    }

    const handleDragEnd = () => {
        setDraggedWorkorder(null)
        stopAutoScroll()
    }

    const handleCreateWorkorders = (request: CreateWorkOrderRequest) => {
        createWorkorders(request)
            .then(() => {
                setFormState({
                    ...formState,
                    createWorkorderForm: false,
                })
            })
            .catch((e) => {
                let errorMessage = <span>Error</span>
                if (e.response?.data?.message !== undefined) {
                    errorMessage = (
                        <Container
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Error
                            </span>
                            <span>- {e.response?.data?.message}</span>
                        </Container>
                    )
                }
                toast.error(errorMessage, {
                    autoClose: 3000,
                })
            })
    }

    const navigateToScheduleDetail = (scheduleId: number) => {
        const project = scheduleList.find(
            (project) => project.id === scheduleId,
        )

        let route = Routes.apartmentScheduleDetail
        if (project && !project.active) {
            route = Routes.apartmentScheduleArchivedDetail
        }

        history.push(resolveRoute(route, ':id', scheduleId))
    }

    const drawerContainerOpen =
        (formState.detailFormOpen.workorder !== null &&
            formState.detailFormOpen.drawerId !== null &&
            formState.detailFormOpen.drawerId !== JbDrawerId.edit) ||
        formState.filterFormOpen

    return (
        <SideDrawerContainer open={drawerContainerOpen} width={DRAWER_W}>
            <Container
                style={{
                    height: 'calc(100vh - 80px)',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    flex: 1,
                }}
            >
                <JobBoardHeader
                    dateFilter={dateFilter}
                    startDate={startDate}
                    endDate={endDate}
                    vendorFilter={vendorFilter}
                    formState={formState}
                    failedServiceRequests={serviceRequestList.length}
                    unassignedWoCount={unassignedWorkorders.length}
                    setDateFilter={setDateFilter}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setVendorFilter={setVendorFilter}
                    getWorkorderData={getWorkorderData}
                    openFilterDrawer={openFilterDrawer}
                    setFormState={setFormState}
                />

                <FilterChips theme={theme} chips={getChipData()} />

                {isLoading ? (
                    <Container
                        style={{
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress size={100} />
                    </Container>
                ) : (
                    <div
                        className={classes.jobBoardContainer}
                        ref={scrollContainerRef}
                        onDragEnd={handleDragEnd}
                    >
                        <div className={classes.tableContainer}>
                            <div className={classes.headerRow}>
                                <div
                                    className={`${classes.dateColumn} ${classes.dateHeaderCell}`}
                                >
                                    Date
                                </div>
                                {sortedVendors.map((vendor) => (
                                    <div
                                        key={vendor.id}
                                        className={classes.vendorHeaderCell}
                                    >
                                        <VendorHeaderCell
                                            vendor={vendor}
                                            theme={theme}
                                            draggedWorkorder={draggedWorkorder}
                                            serviceList={serviceList}
                                            updateVendorServices={
                                                updateVendorServices
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                            {dateRange.map((date) => {
                                const dateKey = format(date, 'yyyy-MM-dd')
                                const isDateToday = isToday(date)
                                const workorderCount =
                                    workorderMap.get(dateKey)?.length ?? 0

                                return (
                                    <div key={dateKey} className={classes.row}>
                                        <div
                                            className={`${classes.dateColumn} ${
                                                classes.dateCell
                                            } ${
                                                isDateToday
                                                    ? classes.todayCell
                                                    : ''
                                            }`}
                                        >
                                            <span className={classes.dateText}>
                                                {format(date, 'EEE, MMM d')}
                                            </span>
                                            <span
                                                className={
                                                    classes.workorderCount
                                                }
                                            >
                                                {workorderCount} workorder
                                                {workorderCount !== 1
                                                    ? 's'
                                                    : ''}
                                            </span>
                                        </div>
                                        {sortedVendors.map((vendor) => (
                                            <div
                                                key={`${dateKey}-${vendor.id}`}
                                                className={
                                                    classes.workorderCell
                                                }
                                            >
                                                <VendorCell
                                                    vendor={vendor}
                                                    dateKey={dateKey}
                                                    workorders={
                                                        workorderMap
                                                            .get(dateKey)
                                                            ?.filter(
                                                                (wo) =>
                                                                    vendor.id ===
                                                                    wo.vendor_id,
                                                            ) ?? []
                                                    }
                                                    theme={theme}
                                                    user={workspaceUser}
                                                    transitionWorkorder={
                                                        transitionWorkorder
                                                    }
                                                    openDetailDrawer={
                                                        openDetailDrawer
                                                    }
                                                    onDragStart={
                                                        handleDragStart
                                                    }
                                                    onDrop={handleDrop}
                                                    onDragOver={handleDragOver}
                                                    draggedWorkorder={
                                                        draggedWorkorder
                                                    }
                                                    isLastColumn={
                                                        vendor.id ===
                                                        sortedVendors[
                                                            sortedVendors.length -
                                                                1
                                                        ].id
                                                    }
                                                    navigateToScheduleDetail={
                                                        navigateToScheduleDetail
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}

                {formState.detailFormOpen.drawerId === JbDrawerId.message && (
                    <SideDrawer
                        open={
                            formState.detailFormOpen.workorder !== null &&
                            formState.detailFormOpen.drawerId ===
                                JbDrawerId.message
                        }
                        width={DRAWER_W}
                        handleClose={() => setFormState(closedJbFormState)}
                        title={'Workorder Messages'}
                    >
                        <MessageForm
                            theme={theme}
                            channelId={
                                formState.detailFormOpen.workorder?.channel ??
                                -1
                            }
                            secondaryChannelId={
                                formState.detailFormOpen.workorder
                                    ?.inv_ins_channel
                            }
                            afterCreateMessage={() => {
                                if (formState.detailFormOpen.workorder) {
                                    updateWorkorderState({
                                        ...formState.detailFormOpen.workorder,
                                        message_count:
                                            (formState.detailFormOpen.workorder
                                                .message_count ?? 0) + 1,
                                    })
                                }
                            }}
                            secondaryTitle="From Inventory Inspection"
                        />
                    </SideDrawer>
                )}

                {formState.detailFormOpen.drawerId === JbDrawerId.unitNotes && (
                    <SideDrawer
                        open={
                            formState.detailFormOpen.workorder !== null &&
                            formState.detailFormOpen.drawerId ===
                                JbDrawerId.unitNotes
                        }
                        width={DRAWER_W}
                        handleClose={() => setFormState(closedJbFormState)}
                        title={`${formState.detailFormOpen.workorder?.unit_name} Unit Notes`}
                    >
                        <MessageForm
                            theme={theme}
                            channelId={
                                formState.detailFormOpen.workorder
                                    ?.unit_channel_id ?? -1
                            }
                            afterCreateMessage={() => {
                                if (formState.detailFormOpen.workorder) {
                                    updateWorkorderState({
                                        ...formState.detailFormOpen.workorder,
                                        unit_notes_msg_count:
                                            (formState.detailFormOpen.workorder
                                                .unit_notes_msg_count ?? 0) + 1,
                                    })
                                }
                            }}
                        />
                    </SideDrawer>
                )}

                <SideDrawer
                    open={formState.filterFormOpen}
                    width={DRAWER_W}
                    handleClose={() => setFormState(closedJbFormState)}
                >
                    <WorkorderFilterForm
                        theme={theme}
                        root={tree.root}
                        finderSelection={finderSelection}
                        serviceList={serviceList}
                        unitConfigList={unitConfigList}
                        workorderFilterState={workorderFilterState}
                        vendorList={vendorList}
                        userList={[]}
                        disabledSections={['timeline', 'vendors', 'users']}
                        setWorkorderFilterState={setWorkorderFilterState}
                    />
                </SideDrawer>

                <Modal
                    open={formState.createWorkorderForm}
                    onClose={() => {
                        setFormState(closedJbFormState)
                        finderSelection.setFinderSelection()
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={formState.createWorkorderForm}>
                        <Paper style={{}}>
                            <CreateWorkorderForm
                                vendorList={vendorList}
                                serviceList={serviceList}
                                theme={theme}
                                root={tree.root}
                                finderSelection={finderSelection}
                                areaConfigMap={areaConfigMap}
                                areaStatusConfigs={areaStatusConfigList ?? []}
                                unitConfigMap={unitConfigMap}
                                areaStatusTagMap={{}}
                                unitWorkorderMap={{}}
                                userList={userList}
                                scheduleList={scheduleList}
                                onClickCreate={handleCreateWorkorders}
                            />
                        </Paper>
                    </Slide>
                </Modal>

                {formState.detailFormOpen.workorder && (
                    <Modal
                        open={
                            formState.detailFormOpen.drawerId ===
                            JbDrawerId.edit
                        }
                        onClose={() => setFormState(closedJbFormState)}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Slide
                            direction="up"
                            in={
                                formState.detailFormOpen.drawerId ===
                                JbDrawerId.edit
                            }
                        >
                            <Paper style={{}}>
                                <WorkorderEditForm
                                    theme={theme}
                                    serviceList={serviceList}
                                    root={tree.root}
                                    userList={userList}
                                    vendorList={vendorList}
                                    workorder={
                                        formState.detailFormOpen.workorder
                                    }
                                    handleClose={() =>
                                        setFormState(closedJbFormState)
                                    }
                                    areaConfigMap={areaConfigMap}
                                    handleSubmit={(request) => {
                                        return updateWorkorder(request)
                                    }}
                                    loading={loadingState.updateWorkorder}
                                />
                            </Paper>
                        </Slide>
                    </Modal>
                )}

                <Modal
                    open={formState.serviceRequestModal}
                    onClose={() => {
                        setFormState(closedJbFormState)
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide direction="up" in={formState.serviceRequestModal}>
                        <Paper>
                            <PendingWorkView
                                serviceRequestList={filteredServiceRequestList}
                                vendorList={vendorList}
                                serviceList={serviceList}
                                serviceRequestFilterState={
                                    serviceRequestFilterState
                                }
                                workorderList={unassignedWorkorders}
                                workspaceUser={workspaceUser}
                                updateWorkorderState={updateWorkorderState}
                                createServiceRequestWorkorder={
                                    createServiceRequestWorkorder
                                }
                                setServiceRequestFilterState={
                                    setServiceRequestFilterState
                                }
                                transitionWorkorder={transitionWorkorder}
                                bulkUpdateWorkorders={bulkUpdateWorkorders}
                            />
                        </Paper>
                    </Slide>
                </Modal>
            </Container>
        </SideDrawerContainer>
    )
}

export enum JbDrawerId {
    message,
    unitNotes,
    edit,
}

export interface JbFormState {
    filterFormOpen: boolean
    createWorkorderForm: boolean
    serviceRequestModal: boolean
    detailFormOpen: {
        workorder: null | WorkorderResponse
        drawerId: null | JbDrawerId
    }
}

export const closedJbFormState: JbFormState = {
    filterFormOpen: false,
    createWorkorderForm: false,
    serviceRequestModal: false,
    detailFormOpen: {
        workorder: null,
        drawerId: null,
    },
}

const DRAWER_W = 420

export const THIS_WEEK = 0
export const NEXT_WEEK = 1
export const CUSTOM = 2

export const ALL = 0
export const IN_HOUSE = 1
export const CONTRACTOR = 2
