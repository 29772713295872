import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useDateRange, useSelectVendorService } from '../../../hooks'
import {
    Workorder,
    IdBoolMap,
    ListVendor,
    User,
    Folder,
    traverse,
    Area,
    ModelMap,
    AreaConfig,
    Service,
} from '../../../models'
import { TimelinePicker } from '../../TimelinePicker'
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    MenuItem,
    Select,
    Switch,
    TextField,
    Theme,
} from '@material-ui/core'
import { Container } from '../../Container'
import ClearIcon from '@material-ui/icons/Clear'
import { Swiper } from '../../Swiper'
import { Selector } from '../../Selector'
import { UpdateWorkOrderRequest } from '../../../store'
import { toast } from 'react-toastify'

interface Props {
    theme: Theme
    root: Folder
    userList: User[]
    vendorList: ListVendor[]
    workorder?: Workorder
    areaConfigMap: ModelMap<AreaConfig>
    serviceList: Service[]
    loading: boolean
    handleClose: () => void
    handleSubmit: (request: UpdateWorkOrderRequest) => Promise<void>
}

export const WorkorderEditForm = (props: Props) => {
    const {
        theme,
        userList,
        vendorList,
        workorder,
        handleClose,
        root,
        areaConfigMap,
        serviceList,
        handleSubmit,
        loading,
    } = props

    const [workOrderTitle, setWorkOrderTitle] = useState('')

    const {
        selectService,
        selectVendor,
        selectedServiceId,
        selectedVendorId,
    } = useSelectVendorService(vendorList)

    // const [swiperState, setSwiperState] = useState({
    //     position: ASSIGN_TO_USER,
    //     companyId: -1,
    //     userId: -1,
    // })

    const [swiperPosition, setSwiperPosition] = useState(ASSIGN_TO_USER)
    const [selectedUserId, setSelectedUserId] = useState(-1)

    const { dateRange, setEndDate, setStartDate, setRange } = useDateRange(
        'EDIT-WORKORDER',
    )

    const [addPrice, setAddPrice] = useState(0)
    const [customPrice, setCustomPrice] = useState<number | null>(null)
    const [priority, setPriority] = useState(false)

    const [areaSelectionState, setAreaSelectionState] = useState<IdBoolMap>({})
    const [unitAreaList, setUnitAreaList] = useState<Area[]>([])

    useEffect(() => {
        if (workorder) {
            if (workorder.unit_id) {
                traverse(root, (folder) => {
                    folder.units.forEach((unit) => {
                        if (unit.id === workorder.unit_id) {
                            setUnitAreaList(unit.areas)
                        }
                    })
                })
            }

            const woServiceAreaSelection: IdBoolMap = {}
            workorder.service_area_list.forEach((sa) => {
                woServiceAreaSelection[sa.area_id] = true
            })

            setAreaSelectionState(woServiceAreaSelection)

            let start: Date | undefined = undefined
            let end: Date | undefined = undefined
            if (workorder.start_date) {
                start = new Date(workorder.start_date)
            }
            if (workorder.end_date) {
                end = new Date(workorder.end_date)
            }

            setAddPrice(workorder.add_price)
            setCustomPrice(workorder.custom_price)
            setPriority(workorder.priority === 1)

            setRange({ startDate: start, endDate: end })
            setWorkOrderTitle(workorder.title)

            selectService(workorder.service_id ?? -1)

            if (workorder.vendor_id) {
                setSwiperPosition(ASSIGN_TO_COMPANY)
                selectVendor(workorder.vendor_id)
                setSelectedUserId(-1)
            } else if (workorder.user_id) {
                setSwiperPosition(ASSIGN_TO_USER)
                selectVendor(-1)
                setSelectedUserId(workorder.user_id)
            } else {
                setSwiperPosition(ASSIGN_TO_USER)
                selectVendor(-1)
                setSelectedUserId(-1)
            }
        }
    }, [workorder])

    return (
        <Container
            style={{
                flexDirection: 'column',
                width: 1500,
                maxWidth: 'calc(100vw - 100px)',
                height: 1100,
                maxHeight: 'calc(100vh - 100px)',
            }}
        >
            {/* Header */}
            <Container
                style={{
                    ...theme.typography.h4,
                    fontWeight: theme.typography.fontWeightBold,
                    alignItems: 'center',
                    borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    padding: theme.spacing(2),
                }}
            >
                <span>Edit Workorder (#{workorder?.id})</span>
            </Container>

            {/* Body */}
            <Container
                style={{
                    flex: 1,
                    overflowY: 'scroll',
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                }}
            >
                {/*Left Column */}
                <Container
                    style={{
                        flexDirection: 'column',
                        width: 400,
                        borderRight: `1px solid ${theme.palette.grey[300]}`,
                    }}
                >
                    {/* Workorder Title */}

                    <Container
                        style={{
                            margin: theme.spacing(2, 2, 1, 2),
                        }}
                    >
                        <TextField
                            fullWidth
                            label="Workorder Title (Required)"
                            variant="outlined"
                            value={workOrderTitle}
                            onChange={(e) => {
                                setWorkOrderTitle(e.target.value)
                            }}
                            inputProps={{ maxLength: 30 }}
                        />
                    </Container>

                    {/* Timeline Picker */}
                    <Container
                        style={{
                            margin: theme.spacing(1, 2, 1, 2),
                        }}
                    >
                        <TimelinePicker
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            onChangeStartDate={setStartDate}
                            onChangeEndDate={setEndDate}
                        />
                    </Container>

                    <Container
                        style={{
                            margin: theme.spacing(1, 2, 1, 2),
                        }}
                    >
                        <Selector
                            customStyle={{
                                formControl: {
                                    flex: 1,
                                    marginRight: theme.spacing(1),
                                },
                            }}
                            data={serviceList}
                            label="Service"
                            searchable
                            maxItems={25}
                            getDisplayString={(srv) => srv.name}
                            currentValue={selectedServiceId}
                            onChange={(e) =>
                                selectService(Number(e.target.value))
                            }
                        />
                        <IconButton
                            onClick={() => {
                                selectService(-1)
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Container>

                    {/* Vendor Selector */}
                    {/*Swiper to assign to vendor or my team member*/}

                    <Container style={{ alignItems: 'center' }}>
                        <Container
                            style={{
                                flexDirection: 'column',
                                margin: theme.spacing(1, 1, 1, 2),
                                padding: theme.spacing(1),
                                paddingLeft: theme.spacing(2),
                                border: `1px solid ${theme.palette.grey[400]}`,
                                borderRadius: 4,
                                width: 310,
                            }}
                        >
                            <Swiper
                                width={280}
                                position={swiperPosition}
                                style={{ overflow: 'hidden' }}
                                onChangePosition={() => {
                                    setSwiperPosition(!swiperPosition)
                                }}
                                leftItem={
                                    <Selector
                                        data={[
                                            { id: -1, name: 'My Team' },
                                            ...vendorList,
                                        ]}
                                        label="My Vendors"
                                        currentValue={selectedVendorId}
                                        getDisplayString={(v) => v.name}
                                        onChange={(e) => {
                                            selectVendor(Number(e.target.value))
                                        }}
                                        searchable
                                        maxItems={25}
                                        variant="standard"
                                        customStyle={{
                                            formControl: { flex: 1 },
                                        }}
                                    />
                                }
                                rightItem={
                                    <Selector
                                        data={[
                                            { id: -1, name: 'My Team' },
                                            ...userList,
                                        ]}
                                        label="My Team"
                                        currentValue={selectedUserId}
                                        getDisplayString={(usr) => usr.name}
                                        onChange={(e) => {
                                            setSelectedUserId(
                                                Number(e.target.value),
                                            )
                                        }}
                                        searchable
                                        maxItems={25}
                                        variant="standard"
                                        customStyle={{
                                            formControl: { flex: 1 },
                                        }}
                                    />
                                }
                            />
                        </Container>
                        <div>
                            <IconButton
                                onClick={() => {
                                    selectVendor(-1)
                                    setSelectedUserId(-1)
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                        </div>
                    </Container>

                    {/* Custom Price Text Field */}
                    <Container
                        style={{
                            margin: theme.spacing(1, 2, 1, 2),
                        }}
                    >
                        <NumberFormat
                            fullWidth
                            label="Custom Price"
                            customInput={TextField}
                            variant="outlined"
                            thousandSeparator
                            fixedDecimalScale
                            decimaleScale={2}
                            prefix="$"
                            placeholder="0.00"
                            value={customPrice ?? 'N/A'}
                            style={{ marginRight: theme.spacing(1) }}
                            onValueChange={(values) => {
                                setCustomPrice(Number(values.value))
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                setCustomPrice(null)
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Container>
                    {/* Add Price Text Field */}
                    <Container
                        style={{
                            margin: theme.spacing(1, 2, 1, 2),
                        }}
                    >
                        <NumberFormat
                            fullWidth
                            label="Add Price"
                            customInput={TextField}
                            variant="outlined"
                            thousandSeparator
                            fixedDecimalScale
                            decimaleScale={2}
                            prefix="$"
                            placeholder="0.00"
                            value={addPrice}
                            onValueChange={(values) => {
                                setAddPrice(Number(values.value))
                            }}
                        />
                    </Container>

                    {/* Priority Radio Button */}
                    <FormControlLabel
                        labelPlacement="end"
                        label={'Priority'}
                        style={{
                            marginLeft: theme.spacing(2),
                        }}
                        control={
                            <Switch
                                checked={priority}
                                color="primary"
                                onClick={() => {
                                    setPriority(!priority)
                                }}
                            />
                        }
                    />
                </Container>

                {/*Right Column */}
                <Container
                    style={{
                        flex: 1,
                        flexDirection: 'column',
                        overflowY: 'scroll',
                    }}
                >
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            margin: theme.spacing(2, 2, 1, 2),
                        }}
                    >
                        Service Areas
                    </span>

                    {/* Area Config Body */}
                    <Container
                        style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        {unitAreaList.map((area) => {
                            return (
                                <Container
                                    key={`AREA-${area.id}`}
                                    style={{
                                        margin: theme.spacing(1),
                                        padding: theme.spacing(1),
                                        border: `1px solid ${theme.palette.grey[400]}`,
                                        borderRadius: theme.shape.borderRadius,
                                        flexDirection: 'column',
                                        minWidth: 176,
                                        maxWidth: 176,
                                        minHeight: 64,
                                        maxHeight: 64,
                                        cursor: 'pointer',
                                        backgroundColor:
                                            theme.palette.grey[200],
                                    }}
                                    onClick={() => {
                                        setAreaSelectionState({
                                            ...areaSelectionState,
                                            [area.id]:
                                                areaSelectionState[area.id] ===
                                                true
                                                    ? false
                                                    : true,
                                        })
                                    }}
                                >
                                    <Container style={{ alignItems: 'center' }}>
                                        <Checkbox
                                            checked={
                                                areaSelectionState[area.id] ===
                                                true
                                            }
                                            onChange={() => {
                                                setAreaSelectionState({
                                                    ...areaSelectionState,
                                                    [area.id]:
                                                        areaSelectionState[
                                                            area.id
                                                        ] === true
                                                            ? false
                                                            : true,
                                                })
                                            }}
                                        />
                                        {/* Area Name */}
                                        <span
                                            style={{
                                                ...theme.typography.subtitle2,
                                                fontWeight:
                                                    theme.typography
                                                        .fontWeightBold,
                                                overflow: 'hidden',
                                                maxLines: 1,
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {
                                                areaConfigMap[area.area_config]
                                                    ?.name
                                            }{' '}
                                            {area.area_label}
                                        </span>
                                    </Container>
                                </Container>
                            )
                        })}
                    </Container>
                </Container>
            </Container>
            {/* Footer */}
            <Container
                style={{
                    margin: theme.spacing(1, 1, 1, 'auto'),
                }}
            >
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        margin: theme.spacing(1),
                        minWidth: 100,
                    }}
                    onClick={handleClose}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    style={{
                        margin: theme.spacing(1),
                        minWidth: 100,
                        backgroundColor: props.loading
                            ? theme.palette.grey[400]
                            : theme.palette.primary.main,
                        color: 'white',
                        textTransform: 'none',
                        cursor: 'pointer',
                    }}
                    disabled={props.loading}
                    onClick={() => {
                        if (workorder) {
                            const serviceAreas: number[] = []
                            areaSelectionState
                            Object.keys(areaSelectionState).forEach(
                                (areaIdStr) => {
                                    const areaId = Number(areaIdStr)
                                    if (areaSelectionState[areaId]) {
                                        serviceAreas.push(areaId)
                                    }
                                },
                            )

                            handleSubmit({
                                workorderId: workorder.id,
                                body: {
                                    title: workOrderTitle,
                                    service: selectedServiceId,
                                    service_areas: serviceAreas,
                                    assign_to_type:
                                        swiperPosition === ASSIGN_TO_COMPANY
                                            ? 'vendor'
                                            : 'user',
                                    assign_to_id:
                                        swiperPosition === ASSIGN_TO_COMPANY
                                            ? selectedVendorId
                                            : selectedUserId,
                                    priority: priority,
                                    start_date: dateRange.startDate?.toISOString(),
                                    end_date: dateRange.endDate?.toISOString(),
                                    add_price: addPrice,
                                    custom_price: customPrice ?? undefined,
                                },
                            })
                                .then(() => {
                                    handleClose()
                                    toast.success(
                                        'Workorder updated successfully!',
                                    )
                                })
                                .catch((e) => {
                                    let errorMessage: string
                                    try {
                                        errorMessage = e.response.data.message
                                    } catch {
                                        errorMessage =
                                            'There was a problem with your request'
                                    }

                                    toast.error(errorMessage)
                                })
                        }
                    }}
                >
                    Save
                </Button>
            </Container>

            {/* Body */}
        </Container>
    )
}

const ASSIGN_TO_COMPANY = false
const ASSIGN_TO_USER = true
