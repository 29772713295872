import React from 'react'

import { Theme, Tooltip } from '@material-ui/core'
import {
    RadioButtonUnchecked,
    MoreHoriz,
    PauseCircleOutline,
    CheckCircleOutline,
    Check,
    VerifiedUser,
    ArrowBackOutlined,
    AttachMoney,
    Help,
} from '@material-ui/icons'

import { BaseWorkorder, getUiStatus, WorkorderStatus } from '../../../models'
import { Container } from '../../Container'
import { usePopover } from '../../../hooks/usePopover'
import { WorkorderTransitionLogPopver } from '../../WorkorderStatusBadge'

interface Props {
    theme: Theme
    workorder: BaseWorkorder
}

export const WorkorderStatusOrb = (props: Props) => {
    const { theme, workorder } = props

    const workorderIcon = getWorkorderIcon(workorder.status, theme)

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    return (
        <Container>
            <Tooltip
                title={getUiStatus(workorder.status)}
                onClick={(e) => {
                    e.stopPropagation()
                    handleOpen(e)
                }}
            >
                {workorderIcon.icon}
            </Tooltip>

            <WorkorderTransitionLogPopver
                open={isOpen}
                anchorEl={anchorEl}
                closePopover={(e) => {
                    const event = e as any
                    event.stopPropagation()
                    handleClose()
                }}
                transitionLogs={workorder.transition_logs}
                workorder={workorder}
            />
        </Container>
    )
}

const getWorkorderIcon = (status: WorkorderStatus, theme: Theme) => {
    switch (status) {
        case WorkorderStatus.ASSIGNED:
            return {
                icon: (
                    <RadioButtonUnchecked
                        style={{ color: theme.palette.ASSIGNED.main }}
                    />
                ),
            }
        case WorkorderStatus.IN_PROGRESS:
            return {
                icon: (
                    <MoreHoriz
                        fontSize="small"
                        style={{
                            color: theme.palette.IN_PROGRESS.main,
                            border: `2px solid ${theme.palette.IN_PROGRESS.main}`,
                            borderRadius: '50%',
                        }}
                    />
                ),
            }
        case WorkorderStatus.PAUSE:
            return {
                icon: (
                    <PauseCircleOutline
                        style={{ color: theme.palette.PAUSE.main }}
                    />
                ),
            }
        case WorkorderStatus.COMPLETE:
            return {
                icon: (
                    <CheckCircleOutline
                        style={{ color: theme.palette.COMPLETE.main }}
                    />
                ),
            }
        case WorkorderStatus.PRE_APPROVED:
            return {
                icon: (
                    <Check style={{ color: theme.palette.PRE_APPROVED.main }} />
                ),
            }
        case WorkorderStatus.APPROVED:
            return {
                icon: (
                    <VerifiedUser
                        style={{ color: theme.palette.APPROVED.main }}
                    />
                ),
            }
        case WorkorderStatus.GO_BACK:
            return {
                icon: (
                    <ArrowBackOutlined
                        fontSize="small"
                        style={{
                            color: theme.palette.GO_BACK.main,
                            border: `2px solid ${theme.palette.GO_BACK.main}`,
                            borderRadius: '50%',
                        }}
                    />
                ),
            }
        case WorkorderStatus.INVOICED:
            return {
                icon: (
                    <AttachMoney
                        style={{ color: theme.palette.INVOICED.main }}
                    />
                ),
            }

        default:
            return {
                icon: <Help />,
            }
    }
}
