import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import {
    AnalyticsHome,
    AreaConfig,
    ComingSoon,
    Home,
    InfrastructureManager,
    InspectionList,
    InvoiceList,
    LeaseHome,
    NationalVendorList,
    PermissionDenied,
    ScheduleList,
    StatusGroupConfig,
    VendorAnalytics,
    VendorScheduler,
    VendorSchedulerLocation,
    VendorSchedulerService,
    Workforce,
    InfrastructureConfig,
    DamageHome,
    PortfolioAnalyticsHome,
} from '../containers'
import { ArchivedSchedule } from '../containers/ArchivedSchedule/ArchivedSchedule'
import { ChangeOrderConfigList } from '../containers/ChangeOrderConfig/ChangeOrderConfig'
import { EZChat } from '../containers/EZChat'
import { Help, VendorHelp } from '../containers/Help'
import { InspectionDetail } from '../containers/Inspection/InspectionDetail'
import { InventoryConfigHome } from '../containers/InventoryConfig'
import { ReportsHome } from '../containers/Reports'
import { SchedulerRoot } from '../containers/Scheduler_v2/SchedulerRoot'
import { Routes } from '../helpers'
import { useUser } from '../hooks'
import Dashboard from '../main/containers/dashboard'
import { USE_WEB, VIEW_INVOICE, VIEW_NATIONAL_VENDOR_LIST } from '../models'
import { hasPermission, isPropertyManager } from '../models/Users/services'
import NationalVendorListFinal from '../main/containers/national-vendor-list'
import AdminLayout from '../main/hoc/admin/AdminLayout'
import { Settings } from '../main/containers/settings'
import { GeneralSetting } from '../main/containers/settings/GeneralSetting'
import { RegisterRouter } from '../main/containers/auth/Register'
import { ForgotPassword } from '../main/containers/auth/ForgotPassword'
import { Login, LoginRouter } from '../main/containers/auth/Login'
import { VendorSettings } from '../containers/VendorSettings'
import { EZChat as EZChatFinal } from '../main/containers/EZChat'
import { NationalPropertyList } from '../containers/NationalPropertyList'
import { VerifyEmail } from '../containers/VerifyEmail/VerifyEmail'
import { NVLHomePage } from '../containers/NationalVendorList/NVLHomePage'
import { PropertyDetails } from '../main/containers/settings/PropertyDetails'
import { EZPayRoot } from '../containers/EZPay/Home/EZPayRoot'
import { ServiceRequestSettings } from '../main/containers/settings/ServiceRequestSettings'
import { ActionItemRoot } from '../containers/ActionItem/ActionItemRoot'
import { ActionItemHome } from '../containers/ActionItem/ActionItemHome'
import { UserRegister } from '../main/containers/auth/Register/UserRegister'
import { RFPDashboard } from '../containers/RFP/RFPDashboard'
import { RFPRoot } from '../containers/RFP/RFPRoot'
import { EZWorkRoot } from '../containers/EZWork/EZWorkRoot'
import { ScheduleHomeRoot } from '../containers'
import { VendorQuestionnaire } from '../main/containers/auth/Questionnaire/VendorQuestionnaire'
import { RegisterStep } from '../models/Company/types'
import { SkillcatQuestionnaire } from '../main/containers/auth/Questionnaire'
import { NVLRoot } from '../containers/NationalVendorList/NVLRoot'
import { DeleteAccount } from '../main/containers/auth/DeleteAccount/DeleteAccount'
import { InvoiceRoot } from '../containers/Invoice/InvoiceRoot'
import { JobBoardRoot } from '../containers/JobBoard/JobBoardRoot'

export const NavigationController = () => {
    const { workspaceUser, showSplash, rootUser } = useUser({
        shouldAuthenticate: true,
        shouldSetup: true,
    })

    if (showSplash) {
        return <div />
    }

    const routes = [
        {
            path: Routes.apartmentScheduleArchivedDetail,
            component: ArchivedSchedule,
            hasPermission: true,
        },
        {
            path: Routes.vendorScheduleDetail,
            component: VendorScheduler,
            hasPermission: true,
        },
        {
            path: Routes.scheduleLocation,
            component: VendorSchedulerLocation,
            hasPermission: true,
        },
        {
            path: Routes.vendorSchedule,
            component: VendorSchedulerService,
            hasPermission: true,
        },
        {
            path: Routes.apartmentScheduleDetail,
            component: SchedulerRoot,
            hasPermission: true,
        },
        {
            path: Routes.apartmentSchedule,
            component: ScheduleHomeRoot,
            hasPermission: true,
        },
        {
            path: Routes.workforce,
            component: Workforce,
            hasPermission: true,
        },
        {
            path: Routes.nvl,
            component: NVLRoot,
            hasPermission: hasPermission(
                workspaceUser,
                VIEW_NATIONAL_VENDOR_LIST,
            ),
        },
        {
            path: Routes.nvl,
            component: NVLRoot,
            hasPermission: hasPermission(
                workspaceUser,
                VIEW_NATIONAL_VENDOR_LIST,
            ),
        },
        {
            path: Routes.inspectionDetail,
            component: InspectionDetail,
            hasPermission: true,
        },
        {
            path: Routes.inspect,
            component: InspectionList,
            hasPermission: true,
        },
        {
            path: Routes.actionItem,
            component: ActionItemRoot,
            hasPermission: true,
        },
        {
            path: Routes.invoiceHome,
            component: InvoiceRoot,
            hasPermission: hasPermission(workspaceUser, VIEW_INVOICE),
        },
        {
            path: Routes.invoiceList,
            component: InvoiceList,
            hasPermission: true,
        },
        {
            path: Routes.workforce,
            component: Workforce,
            hasPermission: true,
        },
        {
            path: Routes.vendorAnalytics,
            component: VendorAnalytics,
            hasPermission: true,
        },
        {
            path: Routes.analytics,
            component: AnalyticsHome,
            hasPermission: true,
        },
        {
            path: Routes.infrastructureManager,
            component: InfrastructureManager,
            hasPermission: true,
        },
        {
            path: Routes.inventoryConf,
            component: InventoryConfigHome,
            hasPermission: true,
        },
        {
            path: Routes.areaConf,
            component: AreaConfig,
            hasPermission: true,
        },
        {
            path: Routes.damageConf,
            component: DamageHome,
            hasPermission: true,
        },
        {
            path: Routes.statusGroups,
            component: StatusGroupConfig,
            hasPermission: true,
        },
        {
            path: Routes.workforce,
            component: Workforce,
            hasPermission: true,
        },
        {
            path: Routes.unitConf,
            component: ComingSoon,
            hasPermission: true,
        },
        {
            path: Routes.ezchat,
            component: EZChatFinal,
            hasPermission: true,
        },
        {
            path: Routes.leases,
            component: LeaseHome,
            hasPermission: true,
        },
        {
            path: Routes.help,
            component: Help,
            hasPermission: true,
        },
        {
            path: Routes.changeOrderConfig,
            component: ChangeOrderConfigList,
            hasPermission: true,
        },
        {
            path: Routes.infrastructureConfig,
            component: InfrastructureConfig,
            hasPermission: true,
        },
        {
            path: Routes.reports,
            component: ReportsHome,
            hasPermission: true,
        },
        {
            path: Routes.home,
            component: Home,
            hasPermission: true,
        },
        {
            path: Routes.dashboard,
            component: Dashboard,
            hasPermission: true,
        },
        {
            path: Routes.nationalVendorListFinal,
            component: NationalVendorListFinal,
            hasPermission: true,
        },
        {
            path: Routes.settingsFinal,
            component: Settings,
            hasPermission: true,
        },
        {
            path: Routes.nationalPropertyList,
            component: NationalPropertyList,
            hasPermission: true,
        },
        {
            path: Routes.generalSetting,
            component: GeneralSetting,
            hasPermission: true,
        },
        {
            path: Routes.vendorSettings,
            component: VendorSettings,
            hasPermission: true,
        },
        {
            key: 'ezchat-final',
            path: Routes.ezChatFinal,
            component: EZChatFinal,
            hasPermission: true,
        },
        {
            path: Routes.vendorHelp,
            component: VendorHelp,
            hasPermission: true,
        },
        {
            path: Routes.ezpay,
            component: EZPayRoot,
            hasPermission: true,
        },
        {
            path: Routes.verify,
            component: VerifyEmail,
            hasPermission: true,
        },
        {
            path: Routes.propertyDetails,
            component: PropertyDetails,
            hasPermission: true,
        },
        {
            path: Routes.rfpDashboard,
            component: RFPRoot,
            hasPermission: true,
        },
        {
            path: Routes.vendorRegister,
            component: VendorQuestionnaire,
            hasPermission: true,
        },
        {
            path: Routes.skillcatRegister,
            component: SkillcatQuestionnaire,
            hasPermission: true,
        },
        {
            path: Routes.ezwork,
            component: EZWorkRoot,
            hasPermission: true,
        },
        {
            path: Routes.portfolioAnalytics,
            component: PortfolioAnalyticsHome,
            hasPermission: true,
        },
        {
            path: Routes.jobBoard,
            component: JobBoardRoot,
            hasPermission: true,
        },
    ]

    const authRoutes = [
        {
            path: Routes.login,
            component: LoginRouter,
            hasPermission: true,
        },
        {
            path: Routes.loginHome,
            component: Login,
            hasPermission: true,
        },
        {
            path: Routes.register,
            component: RegisterRouter,
            hasPermission: true,
        },
        {
            path: Routes.vendorRegister,
            component: VendorQuestionnaire,
            hasPermission: true,
        },
        {
            path: Routes.skillcatRegister,
            component: SkillcatQuestionnaire,
            hasPermission: true,
        },
        {
            path: Routes.forgotPassword,
            component: ForgotPassword,
            hasPermission: true,
        },
        {
            path: Routes.nvl,
            component: NVLRoot,
            hasPermission: true,
        },
        {
            path: Routes.userRegister,
            component: UserRegister,
            hasPermission: true,
        },
        {
            path: Routes.deleteAccount,
            component: DeleteAccount,
            hasPermission: true,
        },
    ]

    const noWorkSpaceRoutes = [
        {
            path: Routes.nvl,
            component: NVLRoot,
            hasPermission: true,
        },
        {
            path: Routes.verify,
            component: VerifyEmail,
            hasPermission: true,
        },
    ]

    const hasNoInfrastructureRoutes = [
        {
            path: Routes.nvl,
            component: NVLRoot,
            hasPermission: true,
        },
        {
            path: Routes.rfpDashboard,
            component: RFPRoot,
            hasPermission: true,
        },
        {
            path: Routes.verify,
            component: VerifyEmail,
            hasPermission: true,
        },
    ]

    const hasWebAppAccess = hasPermission(workspaceUser, USE_WEB)
    let redirectRoute = Routes.login

    let applicationRoutes: any = authRoutes
    if (workspaceUser) {
        applicationRoutes = routes
        if (workspaceUser.active_workspace.company_type === 'VENDOR') {
            if (
                Number(workspaceUser.active_workspace.register_step) ===
                RegisterStep.COMPLETED_STEP
            ) {
                redirectRoute = Routes.ezwork
            } else if (workspaceUser.active_workspace.skillcat) {
                redirectRoute = Routes.skillcatRegister
            } else {
                redirectRoute = Routes.vendorRegister
            }
        } else if (!workspaceUser.active_workspace.has_infrastructure) {
            redirectRoute = Routes.nvl
            applicationRoutes = hasNoInfrastructureRoutes
        } else {
            redirectRoute = Routes.dashboard
        }

        if (!hasWebAppAccess) {
            applicationRoutes = []
            redirectRoute = Routes.permissionDenied
        }
    } else if (rootUser?.workspaces.length === 0) {
        redirectRoute = Routes.nvl
        applicationRoutes = noWorkSpaceRoutes
    } else {
        redirectRoute = Routes.nvl
    }

    return (
        <AdminLayout workspaceUser={workspaceUser} rootUser={rootUser}>
            <Switch>
                {applicationRoutes.map((r: any, idx: number) => {
                    return (
                        <Route
                            component={r.component}
                            path={r.path}
                            key={`APP_ROUTE_${r.path}`}
                        />
                    )
                })}
                <Route
                    component={PermissionDenied}
                    path={Routes.permissionDenied}
                />
                <Redirect key="ROUTE_REDIRECT" from="/" to={redirectRoute} />,
            </Switch>
        </AdminLayout>
    )
}
