import React from 'react'

import { IconButton, Popover, Theme, Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'

import { usePopover } from '../../hooks/usePopover'
import { Container } from '../Container'
import { BaseWorkorder, WorkorderType } from '../../models'

interface Props {
    workorder: BaseWorkorder
    theme: Theme
}

export const WorkorderInfoPopover = (props: Props) => {
    const { workorder, theme } = props

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    let title = 'Workorder Information'
    if (workorder.type === WorkorderType.SERVICE_REQUEST) {
        title = 'Service Request Information'
    } else if (workorder.type === WorkorderType.ACTION_ITEM) {
        title = 'Action Item Information'
    }

    return (
        <Container>
            <IconButton
                onClick={(e) => {
                    e.stopPropagation()
                    handleOpen(e)
                }}
                size="small"
            >
                <Tooltip title={title}>
                    <InfoOutlined color="action" />
                </Tooltip>
            </IconButton>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                onClose={handleClose}
            >
                <Container style={{ flexDirection: 'column', width: '300px' }}>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                            borderBottom: `1px solid ${theme.palette.grey[600]}`,
                            padding: theme.spacing(1),
                        }}
                    >
                        {title}
                    </span>
                    {workorder.info?.description && (
                        <Container
                            style={{
                                flexDirection: 'column',
                                padding: theme.spacing(1),
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    color: theme.palette.grey[700],
                                }}
                            >
                                Description
                            </span>
                            <span>{workorder.info.description}</span>
                        </Container>
                    )}
                    {workorder.info?.recommendations && (
                        <Container
                            style={{
                                flexDirection: 'column',
                                padding: theme.spacing(1),
                            }}
                        >
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    color: theme.palette.grey[800],
                                }}
                            >
                                Recommendations
                            </span>
                            <span>{workorder.info.recommendations}</span>
                        </Container>
                    )}
                </Container>
            </Popover>
        </Container>
    )
}
