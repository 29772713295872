import React, { useMemo } from 'react'

import {
    Avatar,
    IconButton,
    makeStyles,
    Paper,
    Popover,
    Theme,
    Tooltip,
    Typography,
    List,
    ListItem,
    ListItemText,
    Button,
} from '@material-ui/core'
import { HouseOutlined, AddCircleOutline } from '@material-ui/icons'

import { getServiceImageDetails, ListVendor, Service } from '../../models'
import { getInitials } from '../../helpers'
import {
    CompanyDetailActionThunk,
    UpdateVendorServicesRequest,
    WorkorderResponse,
} from '../../store'
import { Container } from '../../components'
import { usePopover } from '../../hooks/usePopover'

interface Props {
    vendor: ListVendor
    theme: Theme
    draggedWorkorder: WorkorderResponse | null
    serviceList: Service[]
    updateVendorServices: (
        req: UpdateVendorServicesRequest,
    ) => CompanyDetailActionThunk
}

const useStyles = makeStyles((theme: Theme) => ({
    headerWrapper: {
        position: 'sticky',
        top: 0,
        zIndex: 3,
        minWidth: 310,
        maxWidth: 310,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        borderBottom: 'none',
        minHeight: 80,
        maxHeight: 90,
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight: theme.spacing(2),
        backgroundColor: theme.palette.primary.dark,
    },
    name: {
        fontWeight: theme.typography.fontWeightBold,
    },
    invalidDropTarget: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    services: {
        minHeight: 30,
        maxHeight: 30,
    },
    popover: {
        padding: theme.spacing(2),
    },
}))

export const VendorHeaderCell = (props: Props) => {
    const {
        vendor,
        theme,
        draggedWorkorder,
        serviceList,
        updateVendorServices,
    } = props
    const classes = useStyles(theme)

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    const isValidDropTarget = draggedWorkorder
        ? vendor.services.some(
              (service) => service.id === draggedWorkorder.service_id,
          )
        : true

    const [firstThreeServices, remainingServices] = useMemo(() => {
        let serviceList = vendor.services
        if (draggedWorkorder) {
            serviceList = serviceList.filter(
                (service) => service.id === draggedWorkorder.service_id,
            )
        }
        const first = serviceList.slice(0, 3)
        const rest = serviceList.slice(3)
        return [first, rest]
    }, [vendor.services, draggedWorkorder])

    const handleAddService = (serviceId: number) => {
        const newServices = vendor.services.map((srv) => ({
            id: srv.id,
            offers: true,
        }))

        newServices.push({ id: serviceId, offers: true })

        updateVendorServices({
            params: { id: vendor.id },
            body: {
                services: newServices,
            },
        })

        handleClose()
    }

    const availableServices = useMemo(() => {
        return serviceList.filter(
            (service) => !vendor.services.some((vs) => vs.id === service.id),
        )
    }, [serviceList, vendor.services])

    return (
        <div className={classes.headerWrapper}>
            <Paper
                className={`${classes.header} ${
                    !isValidDropTarget ? classes.invalidDropTarget : ''
                }`}
                elevation={3}
            >
                <Avatar
                    src={vendor.profile_picture}
                    alt={vendor.name}
                    className={classes.avatar}
                >
                    {getInitials(vendor.name)}
                </Avatar>
                <Container style={{ flexDirection: 'column', flex: 1 }}>
                    <Container style={{ alignItems: 'center' }}>
                        <Typography
                            variant="subtitle1"
                            className={classes.name}
                            style={{ flex: 1 }}
                        >
                            {vendor.name}
                        </Typography>
                        {vendor.in_house && (
                            <Tooltip title="In House">
                                <HouseOutlined />
                            </Tooltip>
                        )}
                    </Container>

                    <Container style={{ alignItems: 'center' }}>
                        <VendorServices
                            vendor={vendor}
                            theme={theme}
                            firstThreeServices={firstThreeServices}
                            remainingServices={remainingServices}
                        />
                        <Container style={{ flex: 1 }} />
                        <Tooltip title="Add Service">
                            <IconButton onClick={handleOpen} size="small">
                                <AddCircleOutline />
                            </IconButton>
                        </Tooltip>
                    </Container>
                </Container>
                <Popover
                    open={isOpen}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className={classes.popover}>
                        <Typography variant="h6">Add Service</Typography>
                        <List>
                            {availableServices.map((service) => (
                                <ListItem
                                    key={service.id}
                                    button
                                    onClick={() => handleAddService(service.id)}
                                >
                                    <ListItemText primary={service.name} />
                                </ListItem>
                            ))}
                        </List>
                        {availableServices.length === 0 && (
                            <Typography>
                                No additional services available
                            </Typography>
                        )}
                        <Button onClick={handleClose}>Cancel</Button>
                    </div>
                </Popover>
            </Paper>
        </div>
    )
}

interface VendorServicesProps {
    firstThreeServices: Service[]
    remainingServices: Service[]
    theme: Theme
    vendor: ListVendor
}

const VendorServices = (props: VendorServicesProps) => {
    const { firstThreeServices, remainingServices, theme, vendor } = props

    const classes = useStyles()

    const { isOpen, anchorEl, handleOpen, handleClose } = usePopover()

    return (
        <>
            <Container
                style={{ alignItems: 'center' }}
                className={classes.services}
            >
                {firstThreeServices.map((service) => {
                    return (
                        <Tooltip
                            title={service.name}
                            key={`SERVICE_${service.id}_${vendor.id}`}
                            style={{ cursor: 'pointer' }}
                        >
                            <img
                                src={getServiceImageDetails(service.id).icon}
                                style={{
                                    maxHeight: 25,
                                    maxWidth: 30,
                                    marginRight: theme.spacing(1),
                                }}
                            />
                        </Tooltip>
                    )
                })}
                {remainingServices.length > 0 && (
                    <Container>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                handleOpen(e)
                            }}
                            size="small"
                        >
                            <span
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                +{remainingServices.length}
                            </span>
                        </IconButton>
                    </Container>
                )}
            </Container>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                onClose={handleClose}
            >
                <Container
                    style={{
                        padding: theme.spacing(2),
                        flexDirection: 'column',
                    }}
                >
                    {remainingServices.map((service) => {
                        return (
                            <Container
                                key={`SERVICE_${service.id}_${vendor.id}`}
                                style={{ alignItems: 'center' }}
                            >
                                <Container
                                    style={{
                                        width: 40,
                                    }}
                                >
                                    <img
                                        src={
                                            getServiceImageDetails(service.id)
                                                .icon
                                        }
                                        style={{
                                            maxHeight: 25,
                                            maxWidth: 30,
                                        }}
                                    />
                                </Container>
                                <Container>
                                    <span
                                        style={{
                                            ...theme.typography.subtitle1,
                                            fontWeight:
                                                theme.typography.fontWeightBold,
                                        }}
                                    >
                                        {service.name}
                                    </span>
                                </Container>
                            </Container>
                        )
                    })}
                </Container>
            </Popover>
        </>
    )
}
