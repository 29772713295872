import React, { useState } from 'react'

import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    TextField,
    Theme,
} from '@material-ui/core'

import { Container, Selector, TimelinePicker } from '../../components'
import { ListVendor, Service, ServiceRequest } from '../../models'
import { useDateRange, useSelectVendorService } from '../../hooks'
import { WorkorderResponse } from '../../store'
import { CreateServiceRequestWorkorderRequest } from '../../hooks/useServiceRequest'

interface Props {
    theme: Theme
    vendorList: ListVendor[]
    serviceList: Service[]
    selectedServiceRequest: ServiceRequest | null
    createServiceRequestWorkorder: (
        request: CreateServiceRequestWorkorderRequest,
    ) => Promise<WorkorderResponse | null>
    updateWorkorderState: (workorder: WorkorderResponse) => void
    setSelectedServiceRequest: React.Dispatch<
        React.SetStateAction<ServiceRequest | null>
    >
}

export const ServiceRequestWorkorderForm = (props: Props) => {
    const {
        theme,
        vendorList,
        serviceList,
        selectedServiceRequest,
        createServiceRequestWorkorder,
        updateWorkorderState,
        setSelectedServiceRequest,
    } = props

    const [title, setTitle] = useState('')

    const [priority, setPriority] = useState(false)
    const [doValidate, setDoValidate] = useState(false)
    const [loading, setLoading] = useState(false)

    const {
        selectService,
        selectVendor,
        selectedServiceId,
        selectedVendorId,
    } = useSelectVendorService(vendorList)

    const { dateRange, setEndDate, setStartDate, setRange } = useDateRange('')

    const checkValidation = () => {
        if (title === '') {
            setDoValidate(true)
            return false
        }
        if (selectedServiceId === -1) {
            setDoValidate(true)
            return false
        }
        if (selectedVendorId === -1) {
            setDoValidate(true)
            return false
        }

        return true
    }

    return (
        <Container style={{ flexDirection: 'column', flex: 1 }}>
            <TextField
                label="Workorder Title"
                value={title}
                onChange={(e) => {
                    setTitle(e.target.value)
                }}
                required
                style={{ margin: theme.spacing(1) }}
                variant="outlined"
                error={doValidate && title === ''}
            />
            <div style={{ margin: theme.spacing(1) }}>
                <TimelinePicker
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    onChangeStartDate={setStartDate}
                    onChangeEndDate={setEndDate}
                />
            </div>
            <Selector
                label="Service"
                data={serviceList}
                searchable
                currentValue={selectedServiceId}
                onChange={(e) => {
                    selectService(e.target.value as number)
                }}
                getDisplayString={(service) => service.name}
                customStyle={{
                    formControl: { margin: theme.spacing(1) },
                }}
                error={doValidate && selectedServiceId === -1}
            />
            <Selector
                label="Vendor"
                data={vendorList}
                searchable
                currentValue={selectedVendorId}
                onChange={(e) => {
                    selectVendor(e.target.value as number)
                }}
                getDisplayString={(vendor) => vendor.name}
                customStyle={{
                    formControl: { margin: theme.spacing(1) },
                }}
                error={doValidate && selectedVendorId === -1}
            />
            <Container
                style={{
                    marginLeft: theme.spacing(1),
                }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={priority}
                            onChange={() => setPriority(!priority)}
                        />
                    }
                    label="Priority"
                />
            </Container>

            <Container style={{ flex: 1 }} />

            <Container
                style={{
                    alignItems: 'center',
                    padding: theme.spacing(1),
                }}
            >
                <Container style={{ flex: 1 }} />

                <Button
                    disabled={loading || !selectedServiceRequest}
                    variant="contained"
                    style={{
                        backgroundColor: theme.palette.primary.main,
                        textTransform: 'none',
                        marginLeft: theme.spacing(1),
                        color: loading
                            ? '#fff'
                            : theme.palette.primary.contrastText,
                    }}
                    onClick={() => {
                        if (
                            !checkValidation() ||
                            selectedServiceRequest === null
                        ) {
                            return
                        }
                        setLoading(true)
                        const request: CreateServiceRequestWorkorderRequest = {
                            service_request: selectedServiceRequest.id,
                            title: title,
                            vendor: selectedVendorId,
                            service: selectedServiceId,
                            start_date: dateRange.startDate?.toISOString(),
                            end_date: dateRange.endDate?.toISOString(),
                            priority: priority,
                        }

                        createServiceRequestWorkorder(request)
                            .then((wo) => {
                                if (wo) {
                                    updateWorkorderState(wo)
                                    setSelectedServiceRequest(null)
                                }
                            })
                            .finally(() => setLoading(false))
                    }}
                >
                    {loading ? (
                        <CircularProgress
                            size={25}
                            style={{ color: 'white' }}
                        />
                    ) : (
                        <span
                            style={{
                                fontWeight: theme.typography.fontWeightBold,
                            }}
                        >
                            {`Create Workorder`}
                        </span>
                    )}
                </Button>
            </Container>
        </Container>
    )
}
