import React, {
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useState,
} from 'react'
import { Container } from '../../Container'

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Checkbox,
    FormControlLabel,
    FormGroup,
    IconButton,
    Switch,
    Tab,
    Tabs,
    Theme,
    createStyles,
    makeStyles,
} from '@material-ui/core'

import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons'
import ClearIcon from '@material-ui/icons/Clear'

import { SchedulerFinder } from '../../../containers/Scheduler_v2/SchedulerFinder'
import {
    Folder,
    ListVendor,
    Service,
    UnitConfig,
    WorkorderStatus,
    User,
    Schedule,
} from '../../../models'
import { FinderSelection } from '../../../hooks'
import { Selector } from '../../Selector'
import { TimelinePicker } from '../../TimelinePicker'
import { WorkorderFilterState } from '../../../contexts'
import { enumKeys } from '../../../helpers'
import { Actionable } from '../../Actionable'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

type SectionOpt = 'timeline' | 'vendors' | 'users'

interface Props {
    root: Folder
    finderSelection: FinderSelection
    theme: Theme
    vendorList: ListVendor[]
    userList: User[]
    serviceList: Service[]
    unitConfigList: UnitConfig[]
    workorderFilterState: WorkorderFilterState
    disabledSections?: SectionOpt[]
    scheduleList?: Schedule[]
    setWorkorderFilterState: (filterState: WorkorderFilterState) => void
}

export const WorkorderFilterForm = (props: Props) => {
    const {
        root,
        finderSelection,
        theme,
        vendorList,
        serviceList,
        unitConfigList,
        workorderFilterState,
        setWorkorderFilterState,
        userList,
        disabledSections,
        scheduleList,
    } = props

    const selectorStyle: React.CSSProperties = {
        margin: theme.spacing(1, 0),
        flex: 1,
    }

    const [tab, setTab] = useState(FILTER_TAB)

    let numSelected = 0
    const allStatuses = enumKeys(WorkorderStatus)
    allStatuses.forEach((key) => {
        numSelected += workorderFilterState.statusFilter[key] ? 1 : 0
    })

    let statusCountLabel = `Filter (${numSelected})`
    if (numSelected === 0 || numSelected === allStatuses.length) {
        statusCountLabel = 'Showing All'
    }

    const timelineDisabled =
        disabledSections !== undefined &&
        disabledSections.find((opt) => opt === 'timeline') !== undefined

    const vendorsDisabled =
        disabledSections !== undefined &&
        disabledSections.find((opt) => opt === 'vendors') !== undefined

    const usersDisabled =
        disabledSections !== undefined &&
        disabledSections.find((opt) => opt === 'users') !== undefined

    return (
        <Container style={{ flexDirection: 'column', flex: 1 }}>
            <Tabs value={tab} onChange={(e, value) => setTab(value)}>
                <Tab label="Filters" value={FILTER_TAB} />
                <Tab label="Assets" value={ASSET_TAB} />
            </Tabs>

            {tab === FILTER_TAB && (
                <Container style={{ flexDirection: 'column' }}>
                    {/* Timeline */}
                    {!timelineDisabled && (
                        <Card
                            style={{
                                padding: theme.spacing(2),
                                borderRadius: 0,
                                flexShrink: 0,
                            }}
                        >
                            <Actionable
                                theme={theme}
                                interactionComponent={
                                    <Switch
                                        value={
                                            workorderFilterState.timeline
                                                .enabled
                                        }
                                        onChange={(_, checked) => {
                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                timeline: {
                                                    ...workorderFilterState.timeline,
                                                    enabled: checked,
                                                },
                                            })
                                        }}
                                    />
                                }
                            >
                                <TimelinePicker
                                    disabled={
                                        !workorderFilterState.timeline.enabled
                                    }
                                    startDate={
                                        workorderFilterState.timeline.startDate
                                    }
                                    endDate={
                                        workorderFilterState.timeline.endDate
                                    }
                                    onChangeStartDate={(d) => {
                                        setWorkorderFilterState({
                                            ...workorderFilterState,
                                            timeline: {
                                                ...workorderFilterState.timeline,
                                                startDate: d,
                                            },
                                        })
                                    }}
                                    onChangeEndDate={(d) => {
                                        setWorkorderFilterState({
                                            ...workorderFilterState,
                                            timeline: {
                                                ...workorderFilterState.timeline,
                                                endDate: d,
                                            },
                                        })
                                    }}
                                />
                            </Actionable>
                        </Card>
                    )}

                    {/* Vendor + Service + Unit Type */}
                    <Card
                        style={{
                            padding: theme.spacing(2),
                            borderRadius: 0,
                            flexShrink: 0,
                        }}
                    >
                        <FormGroup>
                            {/* Vendor Filter*/}
                            {!vendorsDisabled && (
                                <Actionable
                                    theme={theme}
                                    interactionComponent={
                                        <IconButton
                                            onClick={() =>
                                                setWorkorderFilterState({
                                                    ...workorderFilterState,
                                                    vendorIds: [],
                                                })
                                            }
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    }
                                >
                                    <Selector
                                        customStyle={{
                                            formControl: selectorStyle,
                                        }}
                                        data={vendorList}
                                        label="Vendors"
                                        onChange={(e) => {
                                            const selection: number[] = e.target
                                                .value as any

                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                vendorIds: selection,
                                            })
                                        }}
                                        currentValue={
                                            workorderFilterState.vendorIds
                                        }
                                        getDisplayString={(v) => {
                                            return v.name
                                        }}
                                        searchable
                                        multiple
                                    />
                                </Actionable>
                            )}
                            {!usersDisabled && (
                                <Actionable
                                    theme={theme}
                                    interactionComponent={
                                        <IconButton
                                            onClick={() =>
                                                setWorkorderFilterState({
                                                    ...workorderFilterState,
                                                    userIds: [],
                                                })
                                            }
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    }
                                >
                                    <Selector
                                        customStyle={{
                                            formControl: selectorStyle,
                                        }}
                                        data={userList}
                                        label="My Team"
                                        onChange={(e) => {
                                            const selection: number[] = e.target
                                                .value as any

                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                userIds: selection,
                                            })
                                        }}
                                        currentValue={
                                            workorderFilterState.userIds
                                        }
                                        getDisplayString={(usr) => {
                                            return usr.name
                                        }}
                                        searchable
                                        multiple
                                    />
                                </Actionable>
                            )}

                            <Actionable
                                theme={theme}
                                interactionComponent={
                                    <IconButton
                                        onClick={() =>
                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                serviceIds: [],
                                            })
                                        }
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                }
                            >
                                <Selector
                                    data={serviceList}
                                    customStyle={{ formControl: selectorStyle }}
                                    label="Services"
                                    maxItems={10}
                                    onChange={(e) => {
                                        const selection: number[] = e.target
                                            .value as any

                                        setWorkorderFilterState({
                                            ...workorderFilterState,
                                            serviceIds: selection,
                                        })
                                    }}
                                    currentValue={
                                        workorderFilterState.serviceIds
                                    }
                                    getDisplayString={(v) => v.name}
                                    searchable
                                    multiple
                                />
                            </Actionable>

                            <Actionable
                                theme={theme}
                                interactionComponent={
                                    <IconButton
                                        onClick={() =>
                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                unitTypeIds: [],
                                            })
                                        }
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                }
                            >
                                <Selector
                                    data={unitConfigList}
                                    customStyle={{ formControl: selectorStyle }}
                                    label="Unit Types"
                                    onChange={(e) => {
                                        const selection: number[] = e.target
                                            .value as any

                                        setWorkorderFilterState({
                                            ...workorderFilterState,
                                            unitTypeIds: selection,
                                        })
                                    }}
                                    currentValue={
                                        workorderFilterState.unitTypeIds
                                    }
                                    getDisplayString={(v) => v.name}
                                    searchable
                                    multiple
                                />
                            </Actionable>

                            {props.scheduleList && (
                                <Actionable
                                    theme={theme}
                                    interactionComponent={
                                        <IconButton
                                            onClick={() =>
                                                setWorkorderFilterState({
                                                    ...workorderFilterState,
                                                    schedule: -1,
                                                })
                                            }
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    }
                                >
                                    <Selector
                                        data={props.scheduleList}
                                        customStyle={{
                                            formControl: selectorStyle,
                                        }}
                                        label="Schedule"
                                        onChange={(e) => {
                                            const scheduleId: number = e.target
                                                .value as any

                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                schedule: scheduleId,
                                            })
                                        }}
                                        currentValue={
                                            workorderFilterState.schedule
                                        }
                                        getDisplayString={(schedule) =>
                                            schedule.name
                                        }
                                        searchable
                                    />
                                </Actionable>
                            )}
                        </FormGroup>
                    </Card>

                    {/* Workorder Status */}
                    <AccordionSection
                        theme={theme}
                        title1="Workorder Status Filter"
                        title2={statusCountLabel}
                        clearAll={() => {
                            const newStatuses: any = {}
                            allStatuses.forEach((key) => {
                                newStatuses[key] = false
                            })

                            setWorkorderFilterState({
                                ...workorderFilterState,
                                statusFilter: newStatuses,
                            })
                        }}
                    >
                        <FormGroup>
                            <FilterCheckbox
                                checked={
                                    workorderFilterState.statusFilter.ASSIGNED
                                }
                                onChange={(checked) =>
                                    setWorkorderFilterState({
                                        ...workorderFilterState,
                                        statusFilter: {
                                            ...workorderFilterState.statusFilter,
                                            ASSIGNED: checked,
                                        },
                                    })
                                }
                                label="Assigned"
                            />
                            <FilterCheckbox
                                checked={
                                    workorderFilterState.statusFilter
                                        .IN_PROGRESS
                                }
                                onChange={(checked) =>
                                    setWorkorderFilterState({
                                        ...workorderFilterState,
                                        statusFilter: {
                                            ...workorderFilterState.statusFilter,
                                            IN_PROGRESS: checked,
                                        },
                                    })
                                }
                                label="In Progress"
                            />
                            <FilterCheckbox
                                checked={
                                    workorderFilterState.statusFilter.PAUSE
                                }
                                onChange={(checked) =>
                                    setWorkorderFilterState({
                                        ...workorderFilterState,
                                        statusFilter: {
                                            ...workorderFilterState.statusFilter,
                                            PAUSE: checked,
                                        },
                                    })
                                }
                                label="Paused"
                            />
                            <FilterCheckbox
                                checked={
                                    workorderFilterState.statusFilter.COMPLETE
                                }
                                onChange={(checked) =>
                                    setWorkorderFilterState({
                                        ...workorderFilterState,
                                        statusFilter: {
                                            ...workorderFilterState.statusFilter,
                                            COMPLETE: checked,
                                        },
                                    })
                                }
                                label="Complete"
                            />
                        </FormGroup>

                        <FormGroup>
                            <FilterCheckbox
                                checked={
                                    workorderFilterState.statusFilter.GO_BACK
                                }
                                onChange={(checked) =>
                                    setWorkorderFilterState({
                                        ...workorderFilterState,
                                        statusFilter: {
                                            ...workorderFilterState.statusFilter,
                                            GO_BACK: checked,
                                        },
                                    })
                                }
                                label="Go Back"
                            />

                            <FilterCheckbox
                                checked={
                                    workorderFilterState.statusFilter
                                        .PRE_APPROVED
                                }
                                onChange={(checked) =>
                                    setWorkorderFilterState({
                                        ...workorderFilterState,
                                        statusFilter: {
                                            ...workorderFilterState.statusFilter,
                                            PRE_APPROVED: checked,
                                        },
                                    })
                                }
                                label="Preapproved "
                            />

                            <FilterCheckbox
                                checked={
                                    workorderFilterState.statusFilter.APPROVED
                                }
                                onChange={(checked) =>
                                    setWorkorderFilterState({
                                        ...workorderFilterState,
                                        statusFilter: {
                                            ...workorderFilterState.statusFilter,
                                            APPROVED: checked,
                                        },
                                    })
                                }
                                label="Approved "
                            />

                            <FilterCheckbox
                                checked={
                                    workorderFilterState.statusFilter.INVOICED
                                }
                                onChange={(checked) =>
                                    setWorkorderFilterState({
                                        ...workorderFilterState,
                                        statusFilter: {
                                            ...workorderFilterState.statusFilter,
                                            INVOICED: checked,
                                        },
                                    })
                                }
                                label="Invoiced "
                            />
                        </FormGroup>
                    </AccordionSection>

                    {/* Switches */}
                    <AccordionSection
                        theme={theme}
                        title1="More Filters"
                        title2=""
                        clearAll={() =>
                            setWorkorderFilterState({
                                ...workorderFilterState,
                                priority: false,
                                hasUnread: false,
                                hasMessage: false,
                                isGhost: false,
                                isBehind: false,
                                hasChangeOrder: false,
                            })
                        }
                    >
                        <FormGroup>
                            <FormControlLabel
                                labelPlacement="end"
                                label="Priority"
                                style={{ marginRight: theme.spacing(2) }}
                                control={
                                    <Switch
                                        checked={workorderFilterState.priority}
                                        color="primary"
                                        onClick={() => {
                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                priority: !workorderFilterState.priority,
                                            })
                                        }}
                                    />
                                }
                            />
                            <FormControlLabel
                                labelPlacement="end"
                                label={`Unread Messages`}
                                style={{ marginRight: theme.spacing(2) }}
                                control={
                                    <Switch
                                        checked={workorderFilterState.hasUnread}
                                        color="primary"
                                        onClick={() => {
                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                hasUnread: !workorderFilterState.hasUnread,
                                            })
                                        }}
                                    />
                                }
                            />
                            <FormControlLabel
                                labelPlacement="end"
                                label="Has Message"
                                style={{ marginRight: theme.spacing(2) }}
                                control={
                                    <Switch
                                        checked={
                                            workorderFilterState.hasMessage
                                        }
                                        color="primary"
                                        onClick={() => {
                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                hasMessage: !workorderFilterState.hasMessage,
                                            })
                                        }}
                                    />
                                }
                            />
                            <FormControlLabel
                                labelPlacement="end"
                                label="Is EZNow"
                                style={{ marginRight: theme.spacing(2) }}
                                control={
                                    <Switch
                                        checked={workorderFilterState.isEZNow}
                                        color="primary"
                                        onClick={() => {
                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                isEZNow: !workorderFilterState.isEZNow,
                                            })
                                        }}
                                    />
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                labelPlacement="end"
                                label="Ghost"
                                style={{ marginRight: theme.spacing(2) }}
                                control={
                                    <Switch
                                        checked={workorderFilterState.isGhost}
                                        color="primary"
                                        onClick={() => {
                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                isGhost: !workorderFilterState.isGhost,
                                            })
                                        }}
                                    />
                                }
                            />
                            <FormControlLabel
                                labelPlacement="end"
                                label="Behind"
                                style={{ marginRight: theme.spacing(2) }}
                                control={
                                    <Switch
                                        checked={workorderFilterState.isBehind}
                                        color="primary"
                                        onClick={() => {
                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                isBehind: !workorderFilterState.isBehind,
                                            })
                                        }}
                                    />
                                }
                            />
                            <FormControlLabel
                                labelPlacement="end"
                                label="Change Order"
                                style={{ marginRight: theme.spacing(2) }}
                                control={
                                    <Switch
                                        checked={
                                            workorderFilterState.hasChangeOrder
                                        }
                                        color="primary"
                                        onClick={() => {
                                            setWorkorderFilterState({
                                                ...workorderFilterState,
                                                hasChangeOrder: !workorderFilterState.hasChangeOrder,
                                            })
                                        }}
                                    />
                                }
                            />
                        </FormGroup>
                    </AccordionSection>
                </Container>
            )}

            <FormGroup style={{ padding: theme.spacing(2) }}>
                <span
                    style={{
                        ...theme.typography.body1,
                        fontWeight: theme.typography.fontWeightBold,
                    }}
                >
                    Workorder Type
                </span>
                <ToggleButtonGroup
                    value={workorderFilterState.workorderType}
                    onChange={(_, value) => {
                        if (value !== null) {
                            setWorkorderFilterState({
                                ...workorderFilterState,
                                workorderType: value,
                            })
                        }
                    }}
                    aria-label="Workorder Type"
                    exclusive
                    style={{}}
                >
                    <ToggleButton value={1} aria-label="All">
                        All
                    </ToggleButton>
                    <ToggleButton value={2} aria-label="Service Order">
                        Service Order
                    </ToggleButton>
                    <ToggleButton value={3} aria-label="Action Item">
                        Action Item
                    </ToggleButton>
                    <ToggleButton value={4} aria-label="Service Request">
                        Service Request
                    </ToggleButton>
                </ToggleButtonGroup>
            </FormGroup>

            {tab === ASSET_TAB && (
                <SchedulerFinder
                    root={root}
                    finderSelection={finderSelection}
                    areaConfigMap={{}}
                    areaStatusTagMap={{}}
                    unitWorkorderMap={{}}
                />
            )}
        </Container>
    )
}

const FILTER_TAB = 0
const ASSET_TAB = 1

interface FilterCheckboxProps {
    checked: boolean
    label: string
    onChange: (checked: boolean) => void
}

const FilterCheckbox = (props: FilterCheckboxProps) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    color="primary"
                    checked={props.checked}
                    onChange={(_, checked) => props.onChange(checked)}
                />
            }
            label={props.label}
        />
    )
}

interface AccordionSectionProps {
    theme: Theme
    clearAll: () => void
    title1: string
    title2: string
}

const AccordionSection = (
    props: React.PropsWithChildren<AccordionSectionProps>,
) => {
    const { theme } = props

    const styles = useFilterFormStyles()

    const [expanded, setExpanded] = useState(false)

    return (
        <Accordion expanded={expanded}>
            <AccordionSummary onClick={() => setExpanded(!expanded)}>
                <Container
                    style={{
                        flex: 1,
                        alignItems: 'center',
                    }}
                >
                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                        size="small"
                        style={{
                            marginRight: theme.spacing(1),
                        }}
                    >
                        {expanded ? (
                            <KeyboardArrowDown />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </IconButton>

                    {/* Title + toggle */}
                    <Container direction="column">
                        <span className={styles.sectionTitleStyle}>
                            {props.title1}
                        </span>
                    </Container>

                    <div style={{ flex: 1 }} />

                    <Container direction="column" alignItems="flex-end">
                        <span className={styles.lightText}>{props.title2}</span>
                    </Container>

                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            props.clearAll()
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </Container>
            </AccordionSummary>

            <AccordionDetails>{props.children}</AccordionDetails>
        </Accordion>
    )
}

const useFilterFormStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionTitleStyle: {
            ...theme.typography.body1,
            fontWeight: theme.typography.fontWeightBold,
        },
        lightText: {
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightLight,
        },
    }),
)
