import React, { useEffect, useState } from 'react'
import { UpdateWorkOrderRequest, WorkorderResponse } from '../../store'
import {
    Button,
    IconButton,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import { Container, MessageForm } from '../../components'
import {
    WorkorderType,
    getUiStatus,
    getServiceImageDetails,
    activeStatuses,
} from '../../models'
import { toMMDDYYYY } from '../../helpers'
import DomainIcon from '@material-ui/icons/Domain'
import HomeIcon from '@material-ui/icons/Home'

import { Check } from '@material-ui/icons'
import { workorderContext } from '../../contexts'
import { useStyles } from '../../styles'

interface Props {
    workorder: WorkorderResponse
    canSeeUnitNotes: boolean
    setOpenCreateChangeOrder: (open: boolean) => void
}

const CHAT_TAB = 0
const UNIT_NOTES_TAB = 1

export const WorkorderDetail = (props: Props) => {
    const { workorder, setOpenCreateChangeOrder } = props

    const [note, setNote] = useState<string>(workorder.note ?? '')

    const [selectedTab, setSelectedTab] = useState<number>(CHAT_TAB)

    const { updateWorkorder, updateWorkorderState } = workorderContext()

    const theme = useTheme()

    const classes = useStyles()

    let apartmentAddress = ''
    if (workorder.apartment_address) {
        apartmentAddress = apartmentAddress + workorder.apartment_address
    }
    if (workorder.apartment_city) {
        apartmentAddress = apartmentAddress + ', ' + workorder.apartment_city
    }
    if (workorder.apartment_state) {
        apartmentAddress = apartmentAddress + ', ' + workorder.apartment_state
    }

    const startDate = toMMDDYYYY(new Date(workorder.start_date))
    const endDate = toMMDDYYYY(new Date(workorder.end_date))

    useEffect(() => {
        setNote(workorder.note ?? '')
    }, [workorder])

    return (
        <Container
            direction="column"
            flex={1}
            style={{
                maxHeight: 'calc(100vh - 375px)',
                height: 'calc(100vh - 200px)',
            }}
            scrollY
        >
            <Container direction="column">
                <Container style={{ width: '100%' }}>
                    <Container direction="column">
                        <Container style={{ ...theme.typography.h4 }}>
                            {workorder.title ?? ''}
                        </Container>
                        <Container style={{ ...theme.typography.h6 }}>
                            {workorder.apartment_name}
                        </Container>
                        <Container
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.primary.dark,
                            }}
                        >
                            {apartmentAddress}
                        </Container>
                        <Container
                            style={{
                                ...theme.typography.body2,
                                color: theme.palette.primary.dark,
                            }}
                        >
                            {workorder.type === WorkorderType.SERVICE_ORDER
                                ? 'Service Order'
                                : 'Action Item'}
                        </Container>
                    </Container>
                    <Container flex={1} />
                    <Container>
                        {activeStatuses.includes(workorder.status) && (
                            <Button
                                variant="contained"
                                style={{
                                    // margin: theme.spacing(3, 0, 3, 2),
                                    backgroundColor: 'white',
                                    color: theme.palette.primary.dark,
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                    height: '50px',
                                }}
                                onClick={() => setOpenCreateChangeOrder(true)}
                            >
                                + Change Order
                            </Button>
                        )}
                    </Container>
                </Container>
                <Container style={{ marginTop: theme.spacing(2) }}>
                    <Container flex={1} alignItems="center">
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: 600,
                                marginRight: theme.spacing(1),
                            }}
                        >
                            Status
                        </span>
                        <span
                            style={{
                                color: theme.palette.primary.dark,
                            }}
                        >
                            {getUiStatus(workorder.status)}
                        </span>
                    </Container>
                    <Container flex={1} alignItems="center">
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: 600,
                                marginRight: theme.spacing(1),
                            }}
                        >
                            Date Range
                        </span>
                        <span
                            style={{
                                color: theme.palette.primary.dark,
                            }}
                        >
                            {startDate} - {endDate}
                        </span>
                    </Container>
                    <Container flex={1} alignItems="center">
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: 600,
                                marginRight: theme.spacing(1),
                            }}
                        >
                            Schedule ID
                        </span>
                        <span
                            style={{
                                color: theme.palette.primary.dark,
                            }}
                        >
                            {workorder.schedule_id}
                        </span>
                    </Container>
                </Container>
                <Container
                    style={{
                        marginTop: theme.spacing(2),
                        border: `1px solid ${theme.palette.grey[300]}`,
                        borderRadius: theme.shape.borderRadius,
                        padding: theme.spacing(2, 5, 2, 5),
                    }}
                >
                    <Container flex={1} direction="column">
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.primary.dark,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Building
                        </span>
                        {workorder.path && workorder.folder_name && (
                            <Container
                                alignItems="center"
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight: 600,
                                }}
                            >
                                <DomainIcon
                                    color="disabled"
                                    style={{ marginRight: theme.spacing(1) }}
                                />
                                {workorder.path}
                                {workorder.folder_name}/
                            </Container>
                        )}
                    </Container>
                    <Container flex={1} direction="column">
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.primary.dark,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Unit
                        </span>
                        {workorder.unit_name && (
                            <Container
                                alignItems="center"
                                style={{
                                    ...theme.typography.body2,
                                    fontWeight: 600,
                                }}
                            >
                                <HomeIcon
                                    color="disabled"
                                    style={{ marginRight: theme.spacing(1) }}
                                />
                                {workorder.unit_name}
                            </Container>
                        )}
                    </Container>
                    <Container flex={1} direction="column">
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.primary.dark,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Service Areas
                        </span>
                        <span
                            style={{
                                ...theme.typography.body2,
                                fontWeight: 600,
                            }}
                        >
                            {workorder.service_area_list.length}
                        </span>
                    </Container>
                    <Container flex={1} direction="column">
                        <span
                            style={{
                                ...theme.typography.subtitle1,
                                color: theme.palette.primary.dark,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Service
                        </span>
                        {workorder.service_id && workorder.service_name && (
                            <Container alignItems="center">
                                <img
                                    src={
                                        getServiceImageDetails(
                                            workorder.service_id,
                                        ).icon
                                    }
                                    style={{
                                        maxHeight: 25,
                                        maxWidth: 27,
                                        marginRight: theme.spacing(1),
                                    }}
                                />
                                <Container
                                    style={{
                                        ...theme.typography.body2,
                                        fontWeight: 600,
                                    }}
                                >
                                    {workorder.service_name}
                                </Container>
                            </Container>
                        )}
                    </Container>
                </Container>
            </Container>
            <Container
                style={{ marginTop: theme.spacing(1) }}
                direction="column"
            >
                <Container
                    style={{
                        ...theme.typography.h6,
                        fontWeight: 600,
                    }}
                >
                    Team Notes
                </Container>
                <Container>
                    <TextField
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        inputProps={{
                            maxlength: 128,
                        }}
                        style={{ maxWidth: '765px', width: '100%' }}
                        variant="outlined"
                    />
                    <Tooltip title="Apply">
                        <IconButton
                            onClick={() => {
                                const request: UpdateWorkOrderRequest = {
                                    workorderId: workorder.id,
                                    body: {
                                        note: note,
                                    },
                                }
                                updateWorkorder(request)
                            }}
                            style={{
                                padding: '6px',
                                marginLeft: theme.spacing(1),
                            }}
                            size="small"
                        >
                            <Check />
                        </IconButton>
                    </Tooltip>
                </Container>
            </Container>
            <Container direction="column">
                <Container style={{ width: '100%' }}>
                    <Tabs
                        style={{
                            color: 'black',
                            borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
                            flex: 1,
                            textTransform: 'none',
                        }}
                        value={selectedTab}
                        onChange={(_, v) => {
                            if (v !== selectedTab) {
                                setSelectedTab(v)
                            }
                        }}
                        classes={{
                            indicator: classes.indicator,
                        }}
                    >
                        <Tab
                            label="Chat"
                            style={{
                                ...theme.typography.subtitle1,
                                fontWeight: 600,
                                textTransform: 'none',
                            }}
                        />
                        {props.canSeeUnitNotes && (
                            <Tab
                                label="Unit Notes"
                                style={{
                                    ...theme.typography.subtitle1,
                                    fontWeight: 600,
                                    textTransform: 'none',
                                }}
                            />
                        )}
                    </Tabs>
                </Container>
                {selectedTab === CHAT_TAB && (
                    <Container>
                        <MessageForm
                            theme={theme}
                            channelId={workorder.channel}
                            afterCreateMessage={() => {
                                updateWorkorderState({
                                    ...workorder,
                                    message_count:
                                        (workorder.message_count ?? 0) + 1,
                                })
                            }}
                            maxHeight={'300px'}
                            ignoreScrollToEnd
                            secondaryChannelId={workorder?.inv_ins_channel}
                            secondaryTitle="From Inventory Inspection"
                        />
                    </Container>
                )}
                {selectedTab === UNIT_NOTES_TAB && (
                    <Container>
                        <MessageForm
                            theme={theme}
                            channelId={workorder.unit_channel_id ?? undefined}
                            afterCreateMessage={() => {
                                updateWorkorderState({
                                    ...workorder,
                                    unit_notes_msg_count:
                                        (workorder.unit_notes_msg_count ?? 0) +
                                        1,
                                })
                            }}
                            maxHeight={'300px'}
                            ignoreScrollToEnd
                        />
                    </Container>
                )}
            </Container>
        </Container>
    )
}
