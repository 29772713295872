import {
    AreaStatusConfig,
    AreaType,
    Attachment,
    BaseModel,
    CustomStatus,
    Frequency,
    IdentifiableNamedObject,
    IdentifiableObject,
    InventoryConfig,
    RFPType,
    Timeline,
    UnitConfig,
} from '..'
import { WorkorderResponse } from '../../store'
import { Company, ListVendor } from '../Company'
import {
    Area,
    AreaStatus,
    AreaWithConfig,
    BaseFolder,
    Unit,
    UnitMinified,
} from '../Infrastructure'
import { Service } from '../Services'
import { BaseUser, User, WorkSpaceUser } from '../Users'

export enum WorkorderStatus {
    ASSIGNED = 'ASSIGNED',
    IN_PROGRESS = 'IN_PROGRESS',
    PAUSE = 'PAUSE',
    COMPLETE = 'COMPLETE',
    GO_BACK = 'GO_BACK',
    PRE_APPROVED = 'PRE_APPROVED',
    APPROVED = 'APPROVED',
    INVOICED = 'INVOICED',
}

export enum WorkorderTransition {
    ASSIGNED_TO_IN_PROGRESS = 'assigned_to_in_progress',
    IN_PROGRESS_TO_ASSIGNED = 'in_progress_to_assigned',
    IN_PROGRESS_TO_PAUSE = 'in_progress_to_pause',
    IN_PROGRESS_TO_COMPLETE = 'in_progress_to_complete',
    PAUSE_TO_IN_PROGESS = 'pause_to_in_progress',
    COMPLETE_TO_APPROVED = 'complete_to_approved',
    COMPLETE_TO_PRE_APPROVED = 'complete_to_pre_approved',
    COMPLETE_TO_GO_BACK = 'complete_to_go_back',
    PRE_APPROVED_TO_GO_BACK = 'pre_approved_to_go_back',
    PRE_APPROVED_TO_APPROVED = 'pre_approved_to_approved',
    GO_BACK_TO_IN_PROGRESS = 'go_back_to_in_progress',
    APPROVED_TO_INVOICED = 'approved_to_invoiced',
}

export interface Schedule {
    id: number
    name: string
    schedule_id: string
    apartment: any // TODO: ER-91
    start_date: string
    end_date: string
    active: boolean
    baseline_budget: number
    assigned_count: number
    complete_count: number
    approved_count: number
    invoiced_count: number
    inspection_set: number[]
    inspection?: number | null
    inspection_name?: string | null
    schedule_budget_items: ScheduleBudgetItem[]
    unit_count?: number
    most_recent_workorder_report_time?: string
    color: string
    channel: number | null
}
export interface LineItem {
    id: number
    schedule_id: number
    description: string
    cost: number
}

export interface SmartScheduleDetails extends BaseModel {
    name: string
    color: string
    schedule_id: string
    start_date: string
    end_date: string
    active: boolean
    total_service_areas: number
    day_off_list: DayOff[]
    template_units: TemplateUnit[]
    template_services: TemplateService[]
    transfer_date: string | null
    line_items: LineItem[]
}

export interface PortfolioSchedule extends Schedule {
    inspection_assigned: number
    inspection_complete: number
    inspection_handled: number
    inspection_start_date: string | null
    inspection_end_date: string | null
    forecasted_budget: number
    pre_turn_numerator: number
    pre_turn_denominator: number
}

export interface PortfolioBudget {
    invoiced: number
    forecasted: number
    budgeted: number
}

export interface ScheduleBudgetItem {
    id: number
    description: string
    cost: number
}

export interface ServiceArea {
    sa_id: number
    area_id: number
    area_name: string
    area_label: number
    occupiable: boolean
    status: AreaStatus
    area_config_id: number
    part_price: number | null
    area_type: AreaType
}

export interface SubWorkorder {
    id: number
    status: WorkorderStatus
    expense: number
    user: {
        id: number
        name: string
        email: string
    }
}

export interface WorkorderTransitionLog {
    log_id: number
    from_status: WorkorderStatus
    to_status: WorkorderStatus
    transition_user_id: number
    transition_user_name: string
    date: string
}

export interface BaseWorkorder {
    id: number
    add_price: number
    custom_price: number | null
    price_locked: boolean
    schedule_id: number | null
    schedule_name: string | null
    unit_id: number | null
    service_id: number | null
    service_name: string | null
    service_color: string | null
    conflict_id: number | null
    fv_price: number | null
    vendor_id: number | null
    vendor_name: string | null
    service_area_list: ServiceArea[]
    status: WorkorderStatus
    folder_name: string | null
    path: string | null
    sub_workorder: SubWorkorder | null
    total_unit_areas: number
    unit_name: string | null
    user_friendly_schedule_id: string
    unread_count?: number
    message_count: number
    fully_vacant: boolean
    transition_logs: WorkorderTransitionLog[]
    channel_id?: number
    start_date: string | null
    end_date: string | null
    changeorder_set?: ChangeOrder[]
    unit_config_id: number | null
    unit_config_name?: string | null
    unit_channel_id?: number | null
    unit_notes_msg_count?: number
    unread_unit_notes?: number
    channel: number
    subscribe_to_bed_status_change: boolean
    invoiced_price: number | null
    apartment_name: string
    attachment_list: Attachment[]
    title: string
    user_id: number | null
    user_name: string | null
    price_group_id: number | null
    type: WorkorderType
    apartment_id: number
    apartment_address: string | null
    apartment_city: string | null
    apartment_state: string | null
    note: string | null
    active_rfp: number | null
    rfp_type: RFPType | null
    inv_ins_id: number | null
    inv_ins_channel: number | null
    created_date: string
    info?: {
        id: number
        description: string | null
        recommendations: string | null
    } | null
    service_request?: {
        id: number
        lease_id: number
        created_date: string
        tenant: string
    } | null
}

export interface Workorder extends BaseWorkorder {
    priority: 1 | 0
}

export interface _Workorder extends Timeline, BaseWorkorder {
    priority: boolean
    vendor_name: string
    vendor_id: number
}

export interface ListWorkorder {
    id: number
    status: WorkorderStatus
    schedule: number
    unit: number | null
    service: Service | null
    company: Company
}

export interface Conflict {
    id: number
    resolved: boolean
}

export interface ConflictDetail extends Conflict {
    workorders: ListWorkorder[]
}

export interface AreaStatusTag extends IdentifiableObject {
    area_status_config: AreaStatusConfig
    area: AreaWithConfig
    unit: UnitMinified
    folder: BaseFolder
    schedule: number
    locked: boolean
    key: boolean
}

export enum ChangeOrderStatus {
    PENDING = 'Pending',
    APPROVED = 'Approved',
    DENIED = 'Denied',
}

export interface ChangeOrder extends IdentifiableObject {
    status: ChangeOrderStatus
    channel: number | null
    change_order_config: number
    quantity: number
    description: string
    image_0: string | null
    image_0_uploaded: boolean
    image_1: string | null
    image_1_uploaded: boolean
    image_2: string | null
    image_2_uploaded: boolean
    price: number
    income_price: number | null
    location?: {
        unit_name: string | null
        folder_name: string | null
        path: string | null
    }
    vendor?: {
        id: number
        name: string
    } | null
    workorder?: {
        id: number
        status: WorkorderStatus
    } | null
}

export interface ChangeOrderDetail extends ChangeOrder {
    created_by: WorkSpaceUser
    areas: Area[]
    child_workorder: WorkorderResponse | null
}

export interface EndOfTurnReportUnitConfigData {
    complete_count: number
    assigned_count: number
    go_back_count: number
    double_go_back: number
    go_back_max: number
    approved_count: number
}

export interface EndOfTurnReportUnitConfigRow
    extends EndOfTurnReportUnitConfigData,
        IdentifiableObject {
    unit_config: UnitConfig
}
export interface EndOfTurnReport extends IdentifiableObject {
    vendor: ListVendor
    schedule: number
    service: Service
    max_assignments_in_day: number
    averave_assignments_in_day: number
    max_complete_per_day: number
    average_complete_per_day: number
    unit_config_data: EndOfTurnReportUnitConfigRow[]
}

export interface PreTurnConfigItem extends IdentifiableNamedObject {
    organization: number
    archived: boolean
    checked?: boolean
}

export interface PreTurnItem extends IdentifiableObject {
    schedule: number
    checked: boolean
    checkedByUser: User
    config_item: PreTurnConfigItem
}

export enum WorkorderType {
    SERVICE_ORDER = 'SERVICE_ORDER',
    ACTION_ITEM = 'ACTION_ITEM',
    SERVICE_REQUEST = 'SERVICE_REQUEST',
}

export enum WorkorderAutomationType {
    FREQUENCY = 'FREQUENCY',
    TRIGGER = 'TRIGGER',
}

export interface WorkorderTrigger extends IdentifiableObject {
    apartment: Company
    trigger_status: CustomStatus
    inventory_config: InventoryConfig | null
}

export interface WorkorderAutomationConfig extends IdentifiableObject {
    service: Service | null
    unit: Unit | null
    areas: Area[] | null
    assigned_to_user: User | null
    assigned_to_company: IdentifiableNamedObject | null
    title: string
    assigned_by: User | null
    priority: boolean
    days_to_complete: number
    frequency: Frequency | null
    trigger: WorkorderTrigger | null
}

export interface WorkorderFrequencyAutomation
    extends WorkorderAutomationConfig {
    frequency: Frequency
}

export interface WorkorderTriggerAutomation extends WorkorderAutomationConfig {
    trigger: WorkorderTrigger
}

export interface WorkorderGroup extends IdentifiableObject {
    workorders: WorkorderResponse[]
}

export interface WorkorderCluster {
    start_date: string
    end_date: string | null
    workorder_type: WorkorderType
    count: number
    workorder_ids: string
    service: Service
}

export interface ClusterGroup {
    clusters: WorkorderCluster[]
    start_date: string
    end_date: string | null
    service: Service | null
    workorder_type: WorkorderType
    index: number
}

export interface TemplateUnit extends BaseModel {
    schedule: number
    unit: Unit
    is_included: boolean
}

export interface TemplateServiceVendor extends BaseModel {
    template_service: number
    vendor: IdentifiableNamedObject
    capacity: number
    day_off_list: DayOff[]
    start_date: string
    end_date: string
}

export interface TemplateService extends BaseModel {
    schedule: number | null
    service: Service
    position: number
    start_date: string
    end_date: string
    template_service_vendors: TemplateServiceVendor[]
    budget: number
    forecast: number
    variance: number
    invoice: number
}

export interface DayOff extends BaseModel {
    schedule: number | null
    template_service_vendor: number | null
    date: string
}

export interface Suggestion extends BaseModel {
    service: Service
    vendor: Company
    unit: Unit
    start_date: string
    end_date: string
}

export const activeStatuses = [
    WorkorderStatus.ASSIGNED,
    WorkorderStatus.GO_BACK,
    WorkorderStatus.IN_PROGRESS,
    WorkorderStatus.PAUSE,
]
