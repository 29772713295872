import React from 'react'
import { WorkorderResponse } from '../../../store'
import { Theme } from '@material-ui/core'
import { getDifferenceInDays, toMMDDYYYY, toUiTime } from '../../../helpers'
import { Container } from '../../Container'
import { AccessTime } from '@material-ui/icons'
import { WorkorderType } from '../../../models'

interface CardProps {
    workorder: WorkorderResponse
    theme: Theme
}

export const WorkorderDateCard = (props: CardProps) => {
    const { workorder, theme } = props

    const endDate = toMMDDYYYY(new Date(workorder.end_date))

    let daysRemaining = 0
    if (workorder.end_date) {
        const today = new Date()
        daysRemaining = getDifferenceInDays(new Date(workorder.end_date), today)
    }

    let display = (
        <Container>
            <Container style={{ ...theme.typography.caption }}>
                End Date:
            </Container>
            <Container
                style={{
                    fontWeight: theme.typography.fontWeightBold,
                    marginLeft: theme.spacing(1),
                }}
            >
                {endDate}
            </Container>
        </Container>
    )
    if (
        workorder.type === WorkorderType.SERVICE_REQUEST &&
        toMMDDYYYY(new Date(workorder.start_date ?? '')) ===
            toMMDDYYYY(new Date(workorder?.end_date ?? ''))
    ) {
        const startTime = toUiTime(new Date(workorder.start_date ?? ''))
        const endTime = toUiTime(new Date(workorder.end_date ?? ''))
        const timeDisplay = `${startTime} - ${endTime}`

        display = (
            <Container style={{ fontWeight: theme.typography.fontWeightBold }}>
                {timeDisplay}
            </Container>
        )
    }

    return (
        <Container
            flex={1}
            direction="column"
            style={{
                ...theme.typography.body2,
                borderRadius: theme.shape.borderRadius,
                border: `1px solid ${theme.palette.grey[300]}`,
                padding: theme.spacing(1),
                boxSizing: 'border-box',
                margin: theme.spacing(1, 0, 1, 0),
            }}
        >
            <Container alignItems="center">
                {`${daysRemaining}d`}{' '}
                <AccessTime
                    color="secondary"
                    fontSize="small"
                    style={{ marginLeft: theme.spacing(1) }}
                />
            </Container>
            {display}
        </Container>
    )
}
