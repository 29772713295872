import React, { Ref, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import {
    CustomContentGenerator,
    EventClickArg,
    EventContentArg,
    EventSourceInput,
} from '@fullcalendar/core'
import './Calendar.css'

import {
    ClusterGroup,
    Schedule,
    WorkSpaceUser,
    DeadlineDetail,
    ApartmentDeadline,
    WorkorderCluster,
    WorkorderType,
    Folder,
    Service,
    ListVendor,
    User,
    UnitConfig,
    ModelMap,
    AreaConfig,
    LeaseCluster,
} from '../../../../models'
import { CalendarEventType, FormState, defaultFormState } from '../../types'
import { DayCellContainer } from './EventRenderers/WorkorderClusterRenderer'
import { ClusterGroupDetail } from '../ClusterGroupDetail'
import { Routes, resolveRoute, toMMDDYYYY } from '../../../../helpers'
import { ScheduleDetailPopover } from '../ScheduleDetailPopover'
import {
    CreateScheduleRequest,
    CreateWorkOrderRequest,
    ScheduleDetailActionThunk,
    UpdateScheduleRequest,
    WorkorderResponse,
    createWorkorder,
} from '../../../../store'
import { Container, CreateWorkorderForm } from '../../../../components'
import { AddEventPopover } from '../AddEventPopover/AddEventPopover'
import { AddDeadlineModal } from '../AddDeadlineModal/AddDeadlineModal'
import {
    CreateDeadlineRequest,
    FinderSelection,
    _useFinderSelection,
} from '../../../../hooks'
import { BaseProps } from './types'
import { DeadlineDetailModal } from '../Deadline/DeadlineDetailModal'
import { toast } from 'react-toastify'
import { Modal, Paper, Slide } from '@material-ui/core'
import {
    EventTypeDeadline,
    EventTypeProject,
    EventTypeWorkorderCluster,
} from './EventRenderers'
import { EventTypeWrapper } from './EventRenderers/EventTypeWrapper'
import { CreateEZNowModal } from '../../../EZNow/CreateEZNowModal'
import { EventTypeMoveout } from './EventRenderers/EventTypeMoveout'
import { ScheduleListModal } from '../../../ScheduleList/ScheduleListModal'
import { EventTypeMoveIn } from './EventRenderers/EventTypeMoveIn'
import { LeaseModal } from '../LeaseModal/LeaseModal'

interface Props extends BaseProps {
    ref?: Ref<any>
    visibleEvents: CalendarEventType[]
    workorderClusters: WorkorderCluster[]
    moveOutClusters: LeaseCluster[]
    moveInClusters: LeaseCluster[]
    projects: Schedule[]
    apartmentDeadlines: ApartmentDeadline[]
    workspaceUser?: WorkSpaceUser
    loading?: boolean
    root: Folder
    finderSelection: FinderSelection
    serviceList: Service[]
    vendorList: ListVendor[]
    userList: User[]
    areaConfigMap: ModelMap<AreaConfig>
    unitConfigMap: ModelMap<UnitConfig>
    ezNowWorkorderSelectionMap: ModelMap<WorkorderResponse>
    createDeadline: (req: CreateDeadlineRequest) => Promise<ApartmentDeadline>
    createWorkorders: (
        request: CreateWorkOrderRequest,
        createEZNowRequest?: boolean,
    ) => Promise<void>
    transitionDeadline: (
        deadlineId: number,
        complete: boolean,
    ) => Promise<DeadlineDetail>
    createSchedule: (req: CreateScheduleRequest) => ScheduleDetailActionThunk
    updateSchedule: (req: UpdateScheduleRequest) => ScheduleDetailActionThunk
    getDeadlineDetail: (deadlineId: number) => Promise<DeadlineDetail>
    incrementMessageCount: (deadline: ApartmentDeadline) => void
    setEZNowWorkorderSelectionMap: React.Dispatch<
        React.SetStateAction<ModelMap<WorkorderResponse>>
    >
    reloadWorkorderClusters: () => void
    showArchivedProjects: boolean
}

export const Calendar: React.FC<Props> = React.forwardRef<any, Props>(
    (props, ref) => {
        const {
            visibleEvents,
            workorderClusters,
            moveOutClusters,
            moveInClusters,
            projects,
            theme,
            createSchedule,
            updateSchedule,
            apartmentDeadlines,
            getDeadlineDetail,
            workspaceUser,
            transitionDeadline,
            incrementMessageCount,
            ezNowWorkorderSelectionMap,
            setEZNowWorkorderSelectionMap,
        } = props

        const history = useHistory()

        const [formState, setFormState] = useState<FormState>(defaultFormState)

        const [createEZNowRequest, setCreateEZNowRequest] = useState(false)

        const closeForms = () => {
            setFormState(defaultFormState)
        }

        const navigateToScheduleDetail = (scheduleId: number) => {
            const project = projects.find(
                (project) => project.id === scheduleId,
            )

            let route = Routes.apartmentScheduleDetail
            if (project && !project.active) {
                route = Routes.apartmentScheduleArchivedDetail
            }

            history.push(resolveRoute(route, ':id', scheduleId))
        }

        /**
         * View Mode sepecific calendar props
         */
        const events: EventSourceInput = []
        if (visibleEvents.includes(CalendarEventType.PROJECT)) {
            projects.forEach((schedule) => {
                if (schedule.active || props.showArchivedProjects) {
                    const endDate = new Date(schedule.end_date)

                    // Add 1 day to the end date so that the actual end date shows
                    // on the calendar
                    // The calendar excludes the end date by default
                    endDate.setTime(endDate.getTime() + 1000 * 60 * 60 * 24)

                    events.push({
                        title: schedule.name,
                        allDay: true,
                        start: schedule.start_date,
                        end: endDate,
                        backgroundColor: schedule.color,
                        borderColor: schedule.color,
                        textColor: theme.palette.getContrastText(
                            schedule.color,
                        ),
                        extendedProps: {
                            customContent: {
                                type: CalendarEventType.PROJECT,
                                data: schedule,
                            },
                        },
                    })
                }
            })
        }

        if (visibleEvents.includes(CalendarEventType.DEADLINE)) {
            apartmentDeadlines.forEach((aptDeadline) => {
                events.push({
                    title: aptDeadline.deadline.name,
                    start: aptDeadline.deadline.date,
                    extendedProps: {
                        customContent: {
                            type: CalendarEventType.DEADLINE,
                            data: aptDeadline,
                        },
                    },
                })
            })
        }

        if (visibleEvents.includes(CalendarEventType.SERVICE_ORDER)) {
            workorderClusters.forEach((cluster, idx) => {
                if (cluster.workorder_type === WorkorderType.SERVICE_ORDER) {
                    events.push({
                        title: `CLUSTER-${idx}`,
                        allDay: true,
                        start: cluster.start_date,
                        end: cluster.end_date ?? cluster.start_date,
                        backgroundColor: cluster.service?.color,
                        borderColor: cluster.service?.color,
                        textColor: cluster.service
                            ? theme.palette.getContrastText(
                                  cluster.service.color,
                              )
                            : undefined,
                        extendedProps: {
                            customContent: {
                                type: CalendarEventType.SERVICE_ORDER,
                                data: cluster,
                                index: idx,
                            },
                        },
                    })
                }
            })
        }

        if (visibleEvents.includes(CalendarEventType.ACTION_ITEM)) {
            workorderClusters.forEach((cluster, idx) => {
                if (cluster.workorder_type === WorkorderType.ACTION_ITEM) {
                    events.push({
                        title: `CLUSTER-${idx}`,
                        allDay: true,
                        start: cluster.start_date,
                        end: cluster.end_date ?? cluster.start_date,
                        backgroundColor: cluster.service?.color,
                        borderColor: cluster.service?.color,
                        textColor: cluster.service
                            ? theme.palette.getContrastText(
                                  cluster.service.color,
                              )
                            : undefined,
                        extendedProps: {
                            customContent: {
                                type: CalendarEventType.ACTION_ITEM,
                                data: cluster,
                                index: idx,
                            },
                        },
                    })
                }
            })
        }

        if (visibleEvents.includes(CalendarEventType.SERVICE_REQUEST)) {
            workorderClusters.forEach((cluster, idx) => {
                if (cluster.workorder_type === WorkorderType.SERVICE_REQUEST) {
                    events.push({
                        title: `CLUSTER-${idx}`,
                        allDay: true,
                        start: cluster.start_date,
                        end: cluster.end_date ?? cluster.start_date,
                        backgroundColor: cluster.service?.color,
                        borderColor: cluster.service?.color,
                        textColor: cluster.service
                            ? theme.palette.getContrastText(
                                  cluster.service.color,
                              )
                            : undefined,
                        extendedProps: {
                            customContent: {
                                type: CalendarEventType.SERVICE_REQUEST,
                                data: cluster,
                                index: idx,
                            },
                        },
                    })
                }
            })
        }

        if (visibleEvents.includes(CalendarEventType.MOVE_OUT)) {
            moveOutClusters.forEach((cluster) => {
                events.push({
                    title: `MOVE-OUT-${cluster.count}`,
                    start: cluster.date,
                    allDay: false,
                    extendedProps: {
                        customContent: {
                            type: CalendarEventType.MOVE_OUT,
                            data: cluster,
                        },
                    },
                })
            })
        }

        if (visibleEvents.includes(CalendarEventType.MOVE_IN)) {
            moveInClusters.forEach((cluster) => {
                events.push({
                    title: `MOVE-IN-${cluster.count}`,
                    start: cluster.date,
                    allDay: false,
                    extendedProps: {
                        customContent: {
                            type: CalendarEventType.MOVE_IN,
                            data: cluster,
                        },
                    },
                })
            })
        }

        return (
            <>
                <FullCalendar
                    ref={ref}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    height={'100%'}
                    eventContent={(eventContent) => {
                        const customContent =
                            eventContent.event.extendedProps.customContent

                        if (customContent.type === CalendarEventType.PROJECT) {
                            return (
                                <EventTypeWrapper
                                    theme={theme}
                                    eventType={CalendarEventType.PROJECT}
                                >
                                    <EventTypeProject
                                        theme={theme}
                                        project={customContent.data}
                                        onClick={(e, project) => {
                                            setFormState({
                                                ...formState,
                                                meta: {
                                                    xPos: e.clientX,
                                                    yPos: e.clientY,
                                                    date: new Date(),
                                                },
                                                popovers: {
                                                    ...formState.popovers,
                                                    projectPopover: {
                                                        schedule: project,
                                                        open: true,
                                                    },
                                                    addEventPopover: false,
                                                },
                                            })
                                        }}
                                    />
                                </EventTypeWrapper>
                            )
                        }

                        if (customContent.type === CalendarEventType.DEADLINE) {
                            return (
                                <EventTypeWrapper
                                    theme={theme}
                                    eventType={CalendarEventType.DEADLINE}
                                >
                                    <EventTypeDeadline
                                        theme={theme}
                                        deadline={customContent.data}
                                        onClick={(e, deadline) => {
                                            setFormState({
                                                ...formState,
                                                meta: {
                                                    xPos: e.clientX,
                                                    yPos: e.clientY,
                                                    date: new Date(),
                                                },
                                                modals: {
                                                    ...formState.modals,
                                                    deadlineModal: {
                                                        open: true,
                                                        deadlineId: deadline.id,
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                </EventTypeWrapper>
                            )
                        }

                        if (
                            customContent.type ===
                                CalendarEventType.SERVICE_ORDER ||
                            customContent.type ===
                                CalendarEventType.ACTION_ITEM ||
                            customContent.type ===
                                CalendarEventType.SERVICE_REQUEST
                        ) {
                            return (
                                <EventTypeWrapper
                                    theme={theme}
                                    eventType={customContent.type}
                                >
                                    <EventTypeWorkorderCluster
                                        theme={theme}
                                        workorderCluster={customContent.data}
                                        onClick={(e, workorderCluster) => {
                                            setFormState({
                                                ...formState,
                                                modals: {
                                                    ...formState.modals,
                                                    clusterDetailModal: {
                                                        selectedClusterIndex:
                                                            customContent.index,
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                </EventTypeWrapper>
                            )
                        }

                        if (customContent.type === CalendarEventType.MOVE_OUT) {
                            return (
                                <EventTypeWrapper
                                    theme={theme}
                                    eventType={CalendarEventType.MOVE_OUT}
                                >
                                    <EventTypeMoveout
                                        theme={theme}
                                        leaseCluster={customContent.data}
                                        onClick={(e, leaseCluster) => {
                                            setFormState({
                                                ...formState,
                                                modals: {
                                                    ...formState.modals,
                                                    leaseModal: {
                                                        type:
                                                            CalendarEventType.MOVE_OUT,
                                                        leaseCluster: leaseCluster,
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                </EventTypeWrapper>
                            )
                        }

                        if (customContent.type === CalendarEventType.MOVE_IN) {
                            return (
                                <EventTypeWrapper
                                    theme={theme}
                                    eventType={CalendarEventType.MOVE_IN}
                                >
                                    <EventTypeMoveIn
                                        theme={theme}
                                        leaseCluster={customContent.data}
                                        onClick={(e, leaseCluster) => {
                                            setFormState({
                                                ...formState,
                                                modals: {
                                                    ...formState.modals,
                                                    leaseModal: {
                                                        type:
                                                            CalendarEventType.MOVE_IN,
                                                        leaseCluster: leaseCluster,
                                                    },
                                                },
                                            })
                                        }}
                                    />
                                </EventTypeWrapper>
                            )
                        }

                        return null
                    }}
                    customButtons={{
                        addEventButton: {
                            text: '+ Add',
                            hint: 'Add an event',
                            click: (e) => {
                                setFormState({
                                    ...formState,
                                    meta: {
                                        xPos: e.clientX,
                                        yPos: e.clientY,
                                        date: new Date(),
                                    },
                                    popovers: {
                                        ...formState.popovers,
                                        addEventPopover: true,
                                    },
                                })
                            },
                        },
                    }}
                    headerToolbar={{
                        left: 'addEventButton',
                        center: 'title',
                        right: 'today prev,next',
                    }}
                    dateClick={(arg) => {
                        setFormState({
                            ...formState,
                            meta: {
                                xPos: arg.jsEvent.clientX,
                                yPos: arg.jsEvent.clientY,
                                date: arg.date,
                            },
                            popovers: {
                                ...formState.popovers,
                                addEventPopover: true,
                            },
                        })
                    }}
                />

                <AddEventPopover
                    theme={theme}
                    formState={formState}
                    onClose={closeForms}
                    onClickDeadlineType={() => {
                        setFormState({
                            ...formState,
                            modals: {
                                ...formState.modals,
                                addDeadlineModal: true,
                            },
                        })
                    }}
                    onClickProjectType={() => {
                        setFormState({
                            ...formState,
                            popovers: {
                                ...formState.popovers,
                                projectPopover: {
                                    schedule: null,
                                    open: true,
                                },
                                addEventPopover: false,
                            },
                        })
                    }}
                    onClickActionItemType={() => {
                        setFormState({
                            ...formState,
                            modals: {
                                ...formState.modals,
                                addActionItemModal: true,
                            },
                        })
                    }}
                />

                <ClusterGroupDetail
                    theme={theme}
                    formState={formState.modals.clusterDetailModal}
                    onClose={closeForms}
                    workorderClusters={workorderClusters}
                    navigateToScheduleDetail={navigateToScheduleDetail}
                    workspaceUser={workspaceUser}
                    incrementClusterIndex={(amount) => {
                        let newIdx = 0
                        if (formState.modals.clusterDetailModal) {
                            newIdx =
                                formState.modals.clusterDetailModal
                                    .selectedClusterIndex + amount
                            newIdx += amount

                            if (
                                newIdx < 0 ||
                                newIdx >= workorderClusters.length
                            ) {
                                return
                            }
                        }

                        setFormState({
                            ...formState,
                            modals: {
                                ...formState.modals,
                                clusterDetailModal: {
                                    ...formState.modals.clusterDetailModal,
                                    selectedClusterIndex: newIdx,
                                },
                            },
                        })
                    }}
                />

                <Modal
                    open={formState.modals.addActionItemModal}
                    onClose={() => {
                        setFormState(defaultFormState)
                        setCreateEZNowRequest(false)
                    }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Slide
                        direction="up"
                        in={formState.modals.addActionItemModal}
                    >
                        <Paper style={{}}>
                            <CreateWorkorderForm
                                {...props}
                                initialDate={formState.meta.date}
                                unitWorkorderMap={{}}
                                areaStatusTagMap={{}}
                                areaStatusConfigs={[]}
                                onClickCreate={(req) => {
                                    props
                                        .createWorkorders(
                                            req,
                                            createEZNowRequest,
                                        )
                                        .then((res) => {
                                            console.log(res)
                                            toast.success('Success')
                                            setFormState({
                                                ...formState,
                                                modals: {
                                                    ...formState.modals,
                                                    addActionItemModal: false,
                                                    ezNowCreationModal: createEZNowRequest,
                                                },
                                            })
                                            setCreateEZNowRequest(false)
                                            props.reloadWorkorderClusters()
                                        })
                                        .catch((e) => {
                                            toast.error(
                                                'There was a problem creating your action items',
                                            )
                                        })
                                }}
                                createEZNowRequest={createEZNowRequest}
                                setCreateEZNowRequest={setCreateEZNowRequest}
                            />
                        </Paper>
                    </Slide>
                </Modal>

                <AddDeadlineModal
                    theme={theme}
                    onClose={closeForms}
                    formState={formState}
                    onCreate={props.createDeadline}
                />

                <DeadlineDetailModal
                    theme={theme}
                    onClose={closeForms}
                    formState={formState}
                    getDeadlineDetail={getDeadlineDetail}
                    transitionDeadline={transitionDeadline}
                    incrementMessageCount={incrementMessageCount}
                    deadlineList={apartmentDeadlines}
                />

                <ScheduleDetailPopover
                    formState={formState}
                    onClose={closeForms}
                    theme={theme}
                    createSchedule={createSchedule}
                    updateSchedule={updateSchedule}
                    loading={props.loading}
                    navigateToScheduleDetail={navigateToScheduleDetail}
                    workspaceUser={workspaceUser}
                />

                <CreateEZNowModal
                    open={formState.modals.ezNowCreationModal}
                    handleClose={() => {
                        setFormState(defaultFormState)
                    }}
                    selectedWorkordersMap={ezNowWorkorderSelectionMap}
                    setSelectedWorkordersMap={setEZNowWorkorderSelectionMap}
                />

                <LeaseModal
                    open={formState.modals.leaseModal}
                    handleClose={() => {
                        setFormState(defaultFormState)
                    }}
                    theme={theme}
                />
            </>
        )
    },
)
