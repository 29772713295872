import React from 'react'
import {
    IconButton,
    Popover,
    Theme,
    Tooltip,
    useTheme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import { BaseWorkorder, WorkorderTransitionLog } from '../../models'
import { Container } from '../Container'
import { AvatarCircle } from '../AvatarCircle'
import { WoStatusBadge } from '../../containers/VendorScheduler'
import { toMMDDYYYY, toUiTime } from '../../helpers'
import { StatusBadge } from '../StatusBadge/StatusBadge'

export interface GenericTransitionLog {
    to_status: string
    from_status: string
    user_name?: string
    created_date: string
}

interface Props {
    open: boolean
    anchorEl: HTMLElement | null
    closePopover?: (
        event: {},
        reason: 'backdropClick' | 'escapeKeyDown',
    ) => void
    transitionLogs: GenericTransitionLog[] | null
    objectCreatedDate?: string
}

export const TransitionLogPopver = (props: Props) => {
    const {
        open,
        anchorEl,
        closePopover,
        transitionLogs,
        objectCreatedDate,
    } = props

    const theme = useTheme()

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
            }}
            onClose={closePopover}
        >
            <Container
                style={{
                    padding: theme.spacing(1),
                    flexDirection: 'column',
                    maxHeight: 500,
                }}
            >
                <Container style={{ alignItems: 'center' }}>
                    <span
                        style={{
                            ...theme.typography.h6,
                            fontWeight: theme.typography.fontWeightBold,
                        }}
                    >
                        All Transitions
                    </span>
                    <div style={{ flex: 1 }} />
                    <Tooltip title="Cancel">
                        <IconButton
                            onClick={(e) =>
                                closePopover && closePopover(e, 'backdropClick')
                            }
                        >
                            <CloseIcon fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </Container>

                <Container style={{ flexDirection: 'column' }} scrollY>
                    <Container>
                        {objectCreatedDate && (
                            <span
                                style={{
                                    ...theme.typography.subtitle2,
                                    fontWeight: theme.typography.fontWeightBold,
                                }}
                            >
                                Created:{' '}
                                {toMMDDYYYY(new Date(objectCreatedDate))}
                            </span>
                        )}
                    </Container>
                    {transitionLogs?.map((log, idx) => (
                        <TransitionLog
                            key={`TRANSITION_LOG_${idx}`}
                            log={log}
                            theme={theme}
                            marginBottom={
                                idx < transitionLogs.length - 1
                                    ? theme.spacing(1)
                                    : 0
                            }
                        />
                    ))}
                </Container>
            </Container>
        </Popover>
    )
}

interface TLProps {
    log: GenericTransitionLog
    theme: Theme
    marginBottom?: number
}

const TransitionLog = (props: TLProps) => {
    const { log, theme } = props

    return (
        <Container
            style={{
                backgroundColor: theme.palette.grey[200],
                border: `1px solid ${theme.palette.grey[400]}`,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(0.5),
                marginBottom: props.marginBottom,
            }}
        >
            {log.user_name && (
                <AvatarCircle
                    initials
                    text={log.user_name}
                    tooltip={log.user_name}
                />
            )}

            <Container style={{ flexDirection: 'column' }}>
                <Container style={{ marginLeft: theme.spacing(1) }}>
                    <StatusBadge text={log.from_status} />

                    <ArrowRightAltIcon fontSize="large" />

                    <StatusBadge text={log.to_status} />
                </Container>

                <Container style={{ marginLeft: theme.spacing(1) }}>
                    <span
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: theme.typography.fontWeightLight,
                        }}
                    >
                        {toMMDDYYYY(new Date(log.created_date))}
                    </span>

                    <span
                        style={{
                            ...theme.typography.subtitle2,
                            fontWeight: theme.typography.fontWeightLight,
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        {toUiTime(new Date(log.created_date))}
                    </span>
                </Container>
            </Container>
        </Container>
    )
}
